import { Outlet, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import './App.css';
import ConfirmDialog from './components/ConfirmDialog';
import ConfirmDialogAwait from './components/ConfirmDialogAwait';
import ConnectHandler from './components/ConnectHandler';
import Navbar from './components/Navbar';
import ProtectedRoute from './components/ProtectedRoute';
import WebSocketMessageHandler from './components/WebSocketMessageHandler';
import './i18n/i18n';
import AdminHome from './pages/AdminHome';
import AdminSettleHome from './pages/AdminSettleHome';
import AdminSettleSeller from './pages/AdminSettleSeller';
import AdminSettleTeacher from './pages/AdminSettleTeacher';
import ChangePassword from './pages/ChangePassword';
import ConnectionPrep from './pages/ConnectionPrep';
import Login from './pages/Login';
import PaySuccess from './pages/PaySuccess';
import Recharge from './pages/Recharge';
import RechargeList from './pages/RechargeList';
import Register from './pages/Register';
import Room from './pages/Room';
import SellerHome from './pages/SellerHome';
import SellerLogin from './pages/SellerLogin';
import StudentConnectionPrep from './pages/StudentConnectionPrep';
import Home from './pages/StudentHome';
import TeacherHome from './pages/TeacherHome';
import TeacherAppointmentDetails from './pages/TeacherAppointmentDetails';
import TeacherAppointmentConfig from './pages/TeacherAppointmentConfig';
import UserProfile from './pages/UserProfile';
import WSTest from './pages/WSTest';

// 创建布局组件
function Layout() {
  return (
    <>
      <Navbar />
      <Outlet />
    </>
  )
}

// 创建一个包装组件来使用 useLocation
function AppRoutes() {
  return (
    <Routes>
      {/* 公开路由 */}
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/sellerLogin" element={<SellerLogin />} />
      {/* 带Navbar的公开路由 */}
      <Route element={<Layout />}>
        <Route path="/seller" element={<SellerHome />} />
      </Route>

      {/* 受保护路由 */}
      <Route element={
        <ProtectedRoute>
          <Layout />
        </ProtectedRoute>
      }>
        <Route path="/" element={<Home />} />
        <Route path="/prep" element={<ConnectionPrep />} />
        <Route path="/studentPrep" element={<StudentConnectionPrep />} />
        <Route path="/room/:roomId" element={<Room />} />
        <Route path="/teacher" element={<TeacherHome />} />
        <Route path="/teacherAppointmentDetails" element={<TeacherAppointmentDetails />} />
        <Route path="/teacherAppointmentConfig" element={<TeacherAppointmentConfig />} />
        <Route path="/admin" element={<AdminHome />} />
        <Route path="/adminSettle" element={<AdminSettleHome />} />
        <Route path="/adminSettleTeacher" element={<AdminSettleTeacher />} />
        <Route path="/adminSettleSeller" element={<AdminSettleSeller />} />
        <Route path="/profile" element={<UserProfile />} />
        <Route path="/changePassword" element={<ChangePassword />} />
        <Route path="/recharge" element={<Recharge />} />
        <Route path="/rechargeList" element={<RechargeList />} />
        <Route path="/paysuccess" element={<PaySuccess />} />
        <Route path="/wstest" element={<WSTest />} />
      </Route>
    </Routes>
  );
}

function App() {
  return (
    <Router>
      <ConnectHandler />
      <WebSocketMessageHandler />
      <ConfirmDialog />
      <ConfirmDialogAwait />
      <AppRoutes />
    </Router>
  );
}

export default App;
