import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { setConfirmDialog } from '../store/slices/uiSlice';
import './ConfirmDialog.css';

const ConfirmDialog = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { isOpen, title, message, onConfirm, onCancel } = useAppSelector(state => state.ui.confirmDialog);

    if (!isOpen) return null;

    const handleConfirm = () => {
        onConfirm?.();
        dispatch(setConfirmDialog({ isOpen: false }));
    };

    const handleCancel = () => {
        onCancel?.();
        dispatch(setConfirmDialog({ isOpen: false }));
    };

    return (
        <div className="confirm-dialog-overlay">
            <div className="confirm-dialog">
                <h3>{title}</h3>
                <p>{message}</p>
                <div className="confirm-dialog-buttons">
                    <button onClick={handleCancel}>{t('cancel')}</button>
                    <button onClick={handleConfirm}>{t('confirm')}</button>
                </div>
            </div>
        </div>
    );
};

export default ConfirmDialog;