//测试websocket
import React, { useEffect } from 'react';
import config from '../config/config';
import { useAppSelector } from '../store/hooks';

const WSTest: React.FC = () => {
    const { status: wsStatus, error: wsError } = useAppSelector(state => state.websocket);


  useEffect(() => {
    try {
      console.log('Attempting WebSocket connection to:', config.WEBSOCKET_URL_HTTPS);
      
      const socket = new WebSocket(config.WEBSOCKET_URL_HTTPS);
      
      socket.onopen = () => {
        console.info('WebSocket connection established:', {
          url: config.WEBSOCKET_URL_HTTPS,
          readyState: socket.readyState,
          timestamp: new Date().toISOString()
        });
        
        // 发送连接消息
        const connectMessage = {
          type: "connect",
          userId: "test",
          roomId: "test"
        };
        socket.send(JSON.stringify(connectMessage));
      };

      socket.onmessage = (event) => {
        console.log('Received message:', {
          data: JSON.parse(event.data),
          timestamp: new Date().toISOString()
        });
      };

      socket.onerror = (error) => {
        console.error('WebSocket error:', {
          error,
          url: config.WEBSOCKET_URL_HTTPS,
          readyState: socket.readyState,
          browser: navigator.userAgent,
          timestamp: new Date().toISOString()
        });
      };

      socket.onclose = (event) => {
        console.log('WebSocket closed:', {
          code: event.code,
          reason: event.reason,
          wasClean: event.wasClean,
          timestamp: new Date().toISOString()
        });
      };

      return () => {
        if (socket.readyState === WebSocket.OPEN) {
          socket.close();
        }
      };
    } catch (error) {
      console.error('WebSocket creation error:', error);
    }
  }, []);

  return (
    <div>
          <h1>WebSocket Status: {wsStatus}</h1>
    </div>
  );
};

export default WSTest;

