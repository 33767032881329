import React, { useEffect, useState } from 'react';
import { useAppSelector } from '../store/hooks';
import api from '../utils/ApiClient';
import './RechargeList.css';
import { useTranslation } from 'react-i18next';

interface Recharge {
    id: number;
    transactionId: string;
    userId: number;
    amount: number;
    paymentStatus: string;
    paymentMethod: string;
    paymentTime: string;
    refundStatus: string | null;
    refundTime: string | null;
    refundAmount: number | null;
    remarks: string;
    createdAt: string;
}

const RechargeList: React.FC = () => {
    const { t } = useTranslation();
    const { userId } = useAppSelector(state => state.user);
    const [recharges, setRecharges] = useState<Recharge[]>([]);

    const getPaymentStatus = (status: string) => {
        switch (status) {
            case 'PS01': return { text: '待支付', className: 'status-pending' };
            case 'PS02': return { text: '支付成功', className: 'status-success' };
            case 'PS03': return { text: '支付失败', className: 'status-failed' };
            default: return { text: '未知状态', className: 'status-unknown' };
        }
    };

    const getPaymentMethod = (method: string) => {
        switch (method) {
            case 'PM01': return '支付宝';
            case 'PM02': return '微信';
            case 'PM03': return '信用卡';
            default: return '未知方式';
        }
    };

    const getRefundStatus = (status: string | null) => {
        if (!status) return { text: '-', className: '' };
        switch (status) {
            case 'RS01': return { text: '退款处理中', className: 'status-pending' };
            case 'RS02': return { text: '退款成功', className: 'status-success' };
            case 'RS03': return { text: '退款失败', className: 'status-failed' };
            default: return { text: '未知状态', className: 'status-unknown' };
        }
    };

    useEffect(() => {
        fetchRecharges();
    }, []);

    const fetchRecharges = async () => {
        console.log(userId);
        try {
            const response = await api.get<Recharge[]>('/recharge/getList',{
                params: {
                    userId: userId
                }
            });
            setRecharges(response.data);
        } catch (error) {
            console.error('获取订单列表失败:', error);
        }
    };

    const handleRefresh = async (rechargeId: number, rechargeStatus: string) => {
        if (rechargeStatus === 'PS02') { // 已成功
            return;
        }
        try {
            const response = await api.get<Recharge>('/recharge/refresh', {
            params: {
                rechargeId: rechargeId
            }
        });
        setRecharges(recharges.map(recharge => 
            recharge.id === rechargeId ? response.data : recharge
        ));
        } catch (error) {
            console.error('刷新订单失败:', error);
            alert(t('refreshFailed'));
        }
    };

    return (
        <div className="order-list-container page-container">
            <div className="table-container">
                <table className="order-table">
                    <thead>
                        <tr>
                            <th>订单号</th>
                            <th>金额</th>
                            <th>支付状态</th>
                            <th>支付方式</th>
                            <th>支付时间</th>
                            <th>退款状态</th>
                            <th>退款金额</th>
                            <th>备注</th>
                            <th>创建时间</th>
                            <th>操作</th>
                        </tr>
                    </thead>
                    <tbody>
                        {recharges.map((recharge) => (
                            <tr key={recharge.id}>
                                <td>{recharge.id}</td>
                                <td>¥{recharge.amount.toFixed(2)}</td>
                                <td>
                                    <span className={`status-badge ${getPaymentStatus(recharge.paymentStatus).className}`}>
                                        {getPaymentStatus(recharge.paymentStatus).text}
                                    </span>
                                </td>
                                <td>{getPaymentMethod(recharge.paymentMethod)}</td>
                                <td>{new Date(recharge.paymentTime).toLocaleString()}</td>
                                <td>
                                    <span className={`status-badge ${getRefundStatus(recharge.refundStatus).className}`}>
                                        {getRefundStatus(recharge.refundStatus).text}
                                    </span>
                                </td>
                                <td>{recharge.refundAmount ? `¥${recharge.refundAmount.toFixed(2)}` : '-'}</td>
                                <td title={recharge.remarks}>{recharge.remarks}</td>
                                <td>{new Date(recharge.createdAt).toLocaleString()}</td>
                                <td>
                                    <button 
                                        className="refresh-button"
                                        onClick={() => handleRefresh(recharge.id, recharge.paymentStatus)}
                                    >
                                        刷新
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default RechargeList;