interface Config {
  API_URL: string;
  SSE_URL: string;
  WEBSOCKET_URL_HTTP: string;
  WEBSOCKET_URL_HTTPS: string;
  OPENRELAY_STUN_URL: string;
  OPENRELAY_TURN_URL: string;
  OPENRELAY_TURN_URL_TCP: string;
  OPENRELAY_TURN_URL_TLS: string;
  OPENRELAY_TURN_URL_TCP_TLS: string;
  OPENRELAY_TURN_USERNAME: string;
  OPENRELAY_TURN_CREDENTIAL: string;
  USE_GOOGLE_STUN: string;
  USE_SELF_TURN: string;
  USE_OPENRELAY: string;
}

const config: Config = {
  API_URL: process.env.REACT_APP_API_URL || 'http://localhost:8090/api',
  SSE_URL: process.env.REACT_APP_SSE_URL || 'http://localhost:8090/api/stream/teacher',
  WEBSOCKET_URL_HTTP: process.env.REACT_APP_WEBSOCKET_URL_HTTP || 'ws://localhost:8091/signal',
  WEBSOCKET_URL_HTTPS: process.env.REACT_APP_WEBSOCKET_URL_HTTPS || 'wss://localhost:8091/signal',
  OPENRELAY_STUN_URL: process.env.REACT_APP_OPENRELAY_STUN_URL || 'stun:stun.openrelay.metered.ca:80',  
  OPENRELAY_TURN_URL: process.env.REACT_APP_OPENRELAY_TURN_URL || 'turn:turn.openrelay.metered.ca:80',
  OPENRELAY_TURN_URL_TCP: process.env.REACT_APP_OPENRELAY_TURN_URL_TCP || 'turn:turn.openrelay.metered.ca:80?transport=tcp',
  OPENRELAY_TURN_URL_TLS: process.env.REACT_APP_OPENRELAY_TURN_URL_TLS || 'turn:turn.openrelay.metered.ca:443',
  OPENRELAY_TURN_URL_TCP_TLS: process.env.REACT_APP_OPENRELAY_TURN_URL_TCP_TLS || 'turns:turn.openrelay.metered.ca:443?transport=tcp',
  OPENRELAY_TURN_USERNAME: process.env.REACT_APP_OPENRELAY_TURN_USERNAME || 'openrelayproject',
  OPENRELAY_TURN_CREDENTIAL: process.env.REACT_APP_OPENRELAY_TURN_CREDENTIAL || 'openrelayproject',
  USE_GOOGLE_STUN: process.env.REACT_APP_USE_GOOGLE_STUN || 'true',
  USE_SELF_TURN: process.env.REACT_APP_USE_SELF_TURN || 'true',
  USE_OPENRELAY: process.env.REACT_APP_USE_OPENRELAY || 'false',
};

console.log('Environment Variables', {
  API_URL: config.API_URL,
  SSE_URL: config.SSE_URL,
  WEBSOCKET_URL_HTTP: config.WEBSOCKET_URL_HTTP,
  WEBSOCKET_URL_HTTPS: config.WEBSOCKET_URL_HTTPS,
  OPENRELAY_STUN_URL: config.OPENRELAY_STUN_URL,
  OPENRELAY_TURN_URL: config.OPENRELAY_TURN_URL,
  OPENRELAY_TURN_URL_TCP: config.OPENRELAY_TURN_URL_TCP,
  OPENRELAY_TURN_URL_TCP_TLS: config.OPENRELAY_TURN_URL_TCP_TLS,
  OPENRELAY_TURN_USERNAME: config.OPENRELAY_TURN_USERNAME,
  OPENRELAY_TURN_CREDENTIAL: config.OPENRELAY_TURN_CREDENTIAL,
  USE_GOOGLE_STUN: config.USE_GOOGLE_STUN,
  USE_SELF_TURN: config.USE_SELF_TURN,
  USE_OPENRELAY: config.USE_OPENRELAY,
});

export default config; 