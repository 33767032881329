import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { setConfirmDialogAwait } from '../store/slices/uiSlice';
import './ConfirmDialog.css';

const ConfirmDialogAwait = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { isOpen, title, message, resolvePromise } = useAppSelector(state => state.ui.confirmDialogAwait);

    if (!isOpen) return null;

    const handleConfirm = () => {
        resolvePromise?.(true);
        dispatch(setConfirmDialogAwait({ isOpen: false, resolvePromise: undefined }));
    };

    const handleCancel = () => {
        resolvePromise?.(false);
        dispatch(setConfirmDialogAwait({ isOpen: false, resolvePromise: undefined }));
    };

    return (
        <div className="confirm-dialog-overlay">
            <div className="confirm-dialog">
                <h3>{title}</h3>
                <p>{message}</p>
                <div className="confirm-dialog-buttons">
                    <button onClick={handleCancel}>{t('cancel')}</button>
                    <button onClick={handleConfirm}>{t('confirm')}</button>
                </div>
            </div>
        </div>
    );
};

export default ConfirmDialogAwait; 