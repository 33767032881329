import { Navigate, useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import api from '../utils/ApiClient';
import { setUser } from '../store/slices/userSlice';
import { useEffect, useState } from 'react';

interface ProtectedRouteProps {
  children: React.ReactNode;
}

const ProtectedRoute = ({ children }: ProtectedRouteProps) => {
  const { token, isAuthenticated } = useAppSelector(state => state.user);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchUser = async () => {
      if (token && !isAuthenticated) {
        try {
          const response = await api.get('/users');
          const { id, userType, lastName, firstName, avatar } = response.data;
          dispatch(setUser({
            token,
            userId: id,
            userType: userType,
            lastName: lastName,
            firstName: firstName,
            avatar: avatar,
          }));
        } catch (error) {
          console.error('Failed to fetch user:', error);
        }
      }
      setIsLoading(false);
    };

    fetchUser();
  }, [token, isAuthenticated, dispatch]);

  if (isLoading) {
    return <div>Loading...</div>; // 或其他加载组件
  }

  if (!isAuthenticated) {
    if (location.pathname !== '/') {
      return <Navigate to="/login" replace />;
    }
  }

  return children;
};

export default ProtectedRoute; 