import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { WebRTCState } from '../../types/webrtc';

const initialState: WebRTCState = {
  status: 'new',
  localStream: null,
  remoteStream: null,
  error: null,
  isVideoEnabled: true,
  isAudioEnabled: true,
  peerConnection: null
};

export const webrtcSlice = createSlice({
  name: 'webrtc',
  initialState,
  reducers: {
    setStatus: (state, action: PayloadAction<WebRTCState['status']>) => {
      state.status = action.payload;
    },
    setLocalStream: (state, action: PayloadAction<MediaStream | null>) => {
      state.localStream = action.payload;
    },
    setRemoteStream: (state, action: PayloadAction<MediaStream | null>) => {
      state.remoteStream = action.payload;
    },
    setError: (state, action: PayloadAction<string | null>) => {
      state.error = action.payload;
    },
    setVideoEnabled: (state, action: PayloadAction<boolean>) => {
      state.isVideoEnabled = action.payload;
    },
    setAudioEnabled: (state, action: PayloadAction<boolean>) => {
      state.isAudioEnabled = action.payload;
    },
    setPeerConnection: (state, action: PayloadAction<RTCPeerConnection | null>) => {
      state.peerConnection = action.payload;
    },
    // Action creators for middleware
    initialize: (state, action: PayloadAction<{
        roomId: string;
        userId: string;
    }>) => {},
    createOffer: (state, action: PayloadAction<{
        roomId: string;
        userId: string;
    }>) => {},
    handleOffer: (state, action: PayloadAction<{
        roomId: string;
        userId: string;
        offer: RTCSessionDescriptionInit;
    }>) => {},
    handleAnswer: (state, action: PayloadAction<{
        roomId: string;
        userId: string;
        answer: RTCSessionDescriptionInit;
    }>) => {},
    handleIceCandidate: (state, action: PayloadAction<{
        roomId: string;
        userId: string;
        candidate: RTCIceCandidateInit;
    }>) => {},
    cleanup: (state) => {},
    cleanupRemoteStream: (state, action: PayloadAction<{
      roomId: string;
      userId: string;
    }>) => {},
  }
});

export const {
  setStatus,
  setLocalStream,
  setRemoteStream,
  setError,
  setVideoEnabled,
  setAudioEnabled,
  initialize,
  createOffer,
  handleOffer,
  handleAnswer,
  handleIceCandidate,
  cleanup,
  cleanupRemoteStream
} = webrtcSlice.actions;

export default webrtcSlice.reducer; 