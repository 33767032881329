import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import LanguageSwitcher from '../components/LanguageSwitcher';
import ValidateCaptcha from '../components/ValidateCaptcha';
import { useAwaitConfirmDialog } from '../hook/useAwaitConfirmDialog';
import { useAppDispatch } from '../store/hooks';
import { setUser } from '../store/slices/userSlice';
import { UserType } from '../types/user';
import api from '../utils/ApiClient';
import './Login.css';

function Login() {
  const { t } = useTranslation();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showSlider, setShowSlider] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { showConfirmDialogAwait } = useAwaitConfirmDialog();

  // 验证输入信息并显示滑块
  const handleLogin = () => {
    if (!username || !password) {
      showConfirmDialogAwait(t('prompt'), t('fillAllFields'));
      return;
    }
    setShowSlider(true);
  };

  // 验证成功后调用登录接口
  const handleLoginSubmit = async () => {
    try {
      const response = await api.post('/login', {
        username,
        password,
      });

      if (response.status === 200) {
        const { token, id, userType, username, lastName, firstName, avatar } = response.data;
        dispatch(setUser({
          token,
          userId: id,
          userType,
          lastName,
          firstName,
          avatar,
        }));
        if (UserType.ADMIN === userType) {
          navigate('/admin');
        }
        if (UserType.TEACHER === userType) {
          navigate('/teacher');
        }
        if (UserType.STUDENT === userType) {
          navigate('/');
        }
      } else {
        showConfirmDialogAwait(t('prompt'), response.data.message || t('loginFailed'));
      }
    } catch (error: any) {
      showConfirmDialogAwait(t('prompt'), error.response.data || t('loginFailed'));
      setShowSlider(false); // 登录失败时关闭验证框
    }
  };

  return (
    <div className="login-page">
      <div className="login-form">
        <div className="login-header">
          <h1 className="login-title">{(t as any)('login')}</h1>
          <LanguageSwitcher />
        </div>
        <div className="login-content">
          <input
            className="login-input"
            type="text"
            placeholder={(t as any)('username')}
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            autoCapitalize="none"
            autoComplete="off"
            readOnly={showSlider}
          />
          
          <input
            className="login-input"
            type="password"
            placeholder={(t as any)('password')}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            autoComplete="off"
            readOnly={showSlider}
          />
          
          <button
            className="login-submit-btn"
            onClick={handleLogin}
          >
            {(t as any)('loginButton')}
          </button>

          <button
            className="login-register-link"
            onClick={() => navigate('/register')}
          >
            {(t as any)('registerNow')}
          </button>
        </div>

        {showSlider && (
          <ValidateCaptcha
            onSuccess={handleLoginSubmit}
            onClose={() => setShowSlider(false)}
          />
        )}
      </div>
    </div>
  );
}

export default Login;
