import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { websocketSlice } from '../store/slices/websocketSlice';
import { webrtcSlice } from '../store/slices/webrtcSlice';
import { roomSlice } from '../store/slices/roomSlice';

const ConnectHandler = () => {
    const location = useLocation();
    const dispatch = useAppDispatch();
    const { status: wsStatus } = useAppSelector(state => state.websocket);

    useEffect(() => {
        // 只有当 WebSocket 连接存在时才需要处理
        if (wsStatus !== 'disconnected') {
            const currentPath = location.pathname;
            if (!currentPath.includes('/room')) {
                if (!currentPath.includes('/prep') && !currentPath.includes('/studentPrep')) {
                    dispatch(websocketSlice.actions.disconnect());
                    dispatch(webrtcSlice.actions.cleanup());
                    dispatch(roomSlice.actions.reset());
                }
            }
        }
    }, [location.pathname, wsStatus]);

    // 处理页面关闭/刷新
    useEffect(() => {
        const handleBeforeUnload = () => {
            if (wsStatus !== 'disconnected') {
                dispatch(websocketSlice.actions.disconnect());
                dispatch(webrtcSlice.actions.cleanup());
                dispatch(roomSlice.actions.reset());
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [wsStatus]);

    return null; // 这个组件不需要渲染任何内容
};

export default ConnectHandler;