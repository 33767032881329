import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserState, UpdateUserPayload } from '../../types/user';

const initialState: UserState = {
  token: localStorage.getItem('token'),
  userId: null,
  userType: null,
  lastName: null,
  firstName: null,
  avatar: null,
  isAuthenticated: false,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<{
      token: string;
      userId: string;
      userType: string;
      lastName: string;
      firstName: string;
      avatar: string;
    }>) => {
      state.token = action.payload.token;
      state.userId = action.payload.userId;
      state.userType = action.payload.userType;
      state.lastName = action.payload.lastName;
      state.firstName = action.payload.firstName;
      state.avatar = action.payload.avatar;
      state.isAuthenticated = true;
      
      // 同时更新 localStorage
      localStorage.setItem('token', action.payload.token);
    },
    setAuthenticated: (state, action: PayloadAction<boolean>) => {
      state.isAuthenticated = action.payload;
    },
    updateUser: (state, action: PayloadAction<UpdateUserPayload>) => {
      return { ...state, ...action.payload };
    },
    updateAvatar: (state, action: PayloadAction<string>) => {
      state.avatar = action.payload;
    },
    logout: (state) => {
      state.token = null;
      state.userId = null;
      state.userType = null;
      state.lastName = null;
      state.firstName = null;
      state.avatar = null;
      state.isAuthenticated = false;
      
      // 清除 localStorage
      localStorage.removeItem('token');
      localStorage.removeItem('userId');
    },
  },
});

export const { setUser, updateAvatar, updateUser, logout } = userSlice.actions;
export default userSlice.reducer;
