import React, { useState, useEffect } from 'react';
import api from '../utils/ApiClient';
import './AdminHome.css';

interface UserInfo {
  [key: string]: string | boolean;  // 用户信息键值对
}

interface Room {
  [roomId: string]: Set<UserInfo>;  // roomId -> Set of user info
}

interface RoomTimer {
  running: boolean;
  teacherId: string;
}

interface Timers {
  [roomId: string]: RoomTimer;
}

const AdminHome = () => {
  const [rooms, setRooms] = useState<Room>({});
  const [timers, setTimers] = useState<Timers>({});
  const [sessions, setSessions] = useState<string[]>([]);
  const [loading, setLoading] = useState(true);
  const [referralCode, setReferralCode] = useState('');
  const [sellerInfo, setSellerInfo] = useState<{ username: string } | null>(null);

  const fetchData = async () => {
    try {
      const [roomsRes, timersRes, socketsRes] = await Promise.all([
        api.get('/admin/room-container'),
        api.get('/admin/timers'),
        api.get('/admin/socket-container')
      ]);

      setRooms(roomsRes.data);
      console.log(timersRes.data);
      setTimers(timersRes.data);
      setSessions(socketsRes.data);
    } catch (error) {
      console.error('获取数据失败:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    const interval = setInterval(fetchData, 30000);
    return () => clearInterval(interval);
  }, []);

  const handleCreateSeller = async () => {
    if (!referralCode.trim()) {
      alert('请输入推荐码');
      return;
    }

    try {
      const response = await api.post('/sellers/create', { referralCode });
      setSellerInfo(response.data);
      setReferralCode('');
    } catch (error: any) {
      if (error.response) {
        alert(error.response.data || '创建失败');
      } else if (error.request) {
        alert('服务器无响应，请稍后重试');
      } else {
        alert('请求失败，请稍后重试');
      }
    }
  };

  const handleRefreshTeacherList = async () => {
    try {
      await api.get('/admin/teachers/refresh').then(res => {
        if (res.data === true) {
          alert('刷新成功');
        } else {
          alert('刷新失败');
        }
      });
    } catch (error) {
      alert('刷新失败，请稍后重试');
    }
  };

  const handleCopy = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      alert('复制成功');
    } catch (err) {
      alert('复制失败，请手动复制');
    }
  };

  if (loading) {
    return <div className="admin-loading">加载中...</div>;
  }

  return (
    <div className="admin-container">
      {/* 创建销售员部分 */}
      <div className="create-seller-section">
        <h2 className="section-title">创建销售员</h2>
        <div className="create-seller-card">
          <input
            type="text"
            className="sell-code-input"
            placeholder="请输入推荐码"
            value={referralCode}
            onChange={(e) => setReferralCode(e.target.value)}
          />
          <button className="create-button" onClick={handleCreateSeller}>
            创建销售员
          </button>
        </div>

        {sellerInfo && (
          <div className="seller-card">
            <div className="seller-info">
              <span className="username-label">销售员账号：</span>
              <span className="username-value">{sellerInfo.username}</span>
              <button
                className="copy-button"
                onClick={() => handleCopy(sellerInfo.username)}
              >
                复制账号
              </button>
            </div>
          </div>
        )}
      </div>

      <div className="create-seller-section">
        <h2 className="section-title">刷新教师列表</h2>
        <div className="create-seller-card">
          <button className="create-button" onClick={handleRefreshTeacherList}>
            刷新教师列表
          </button>
        </div>
      </div>

      {/* 系统监控部分 */}
      <div className="admin-dashboard">
        <div className="dashboard-header">
          <h2 className="section-title">系统监控</h2>
          <button onClick={fetchData} className="refresh-button">
            刷新数据
          </button>
        </div>

        <div className="dashboard-grid">
          {/* 房间信息卡片 */}
          <div className="dashboard-card">
            <h2>房间状态 ({Object.keys(rooms).length})</h2>
            <div className="card-content">
              {Object.entries(rooms).map(([roomId, userSet]) => {
                const userArray = Array.from(userSet);
                const teacher = userArray.find(userInfo => userInfo['isOwner'] === true);
                const students = userArray.filter(userInfo => userInfo['isOwner'] === false);

                return (
                  <div key={roomId} className="info-item">
                    <div className="item-header">
                      <span className="item-title">房间 <span className="room-id">{roomId}</span></span>
                      <span className="status-badge active">{userArray.length} 人</span>
                    </div>
                    <div className="item-details">
                      <p>教师: {teacher ? (
                        <>
                          <span className="teacher-id">{teacher['userId']}</span>
                          <span className="session-id">({teacher['sessionId']})</span>
                        </>
                      ) : '无'}</p>
                      <p>学生: {students.map((student, index) => (
                        <React.Fragment key={String(student['sessionId'])}>
                          {index > 0 && ', '}
                          <span className="student-id">{student['userId']}</span>
                          <span className="session-id">({student['sessionId']})</span>
                        </React.Fragment>
                      )) || '无'}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          {/* 计时器信息卡片 */}
          <div className="dashboard-card">
            <h2>计时器 ({Object.keys(timers).length})</h2>
            <div className="card-content">
              {Object.entries(timers).map(([roomId, timer]) => (
                <div key={roomId} className="info-item">
                  <div className="item-header">
                    <span className="item-title">房间 <span className="room-id">{roomId}</span></span>
                    <span className={`status-badge ${timer.running ? 'active' : 'inactive'}`}>
                      {timer.running ? '运行中' : '已停止'}
                    </span>
                  </div>
                  <div className="item-details">
                    <p>教师ID: <span className="teacher-id">{timer.teacherId}</span></p>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* WebSocket连接信息卡片 */}
          <div className="dashboard-card">
            <h2>WebSocket连接 ({sessions.length})</h2>
            <div className="card-content">
              {sessions.map((sessionId) => (
                <div key={sessionId} className="info-item">
                  <div className="item-header">
                    <span className="item-title">用户 {sessionId}</span>
                    <span className="status-badge active">已连接</span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminHome;