export enum SettleStatus {
  PADDING = 'ST01',
  FINISHED = 'ST02',
  CANCEL = 'ST03',
  FAILED = 'ST04'
}

// 用户类型标签映射
export const SettleStatusLabel: Record<SettleStatus, string> = {
    [SettleStatus.PADDING]: 'padding',
    [SettleStatus.FINISHED]: 'finished',
    [SettleStatus.CANCEL]: 'canceled',
    [SettleStatus.FAILED]: 'failed'
};

// 类型保护函数
export function isValidSettleStatus(status: string | null): status is SettleStatus {
    return status !== null && Object.values(SettleStatus).includes(status as SettleStatus);
}