import { Middleware } from '@reduxjs/toolkit';
import config from '../../config/config';
import { webrtcSlice } from '../slices/webrtcSlice';
import { sendConnectionMessage } from '../slices/websocketSlice';

let peerConnection: RTCPeerConnection | null = null;
let keepAliveInterval: NodeJS.Timeout | null = null;
let keepAliveChannel: RTCDataChannel | null = null;
let pendingIceCandidates: RTCIceCandidate[] = [];

// 1. 缩短保活间隔
const KEEPALIVE_INTERVAL = 10000; // 改为10秒

// ICE服务器配置
const configuration = {
  iceServers: [
    ...(config.USE_OPENRELAY === 'true' ? [
      // OpenRelay servers (主要)
      { 
        urls: config.OPENRELAY_STUN_URL
      },
      {
        urls: config.OPENRELAY_TURN_URL,
        username: config.OPENRELAY_TURN_USERNAME,
        credential: config.OPENRELAY_TURN_CREDENTIAL,
      },
      {
        urls: config.OPENRELAY_TURN_URL_TCP,
        username: config.OPENRELAY_TURN_USERNAME,
        credential: config.OPENRELAY_TURN_CREDENTIAL,
      },
      {
        urls: config.OPENRELAY_TURN_URL_TLS,
        username: config.OPENRELAY_TURN_USERNAME,
        credential: config.OPENRELAY_TURN_CREDENTIAL,
      },
      {
        urls: config.OPENRELAY_TURN_URL_TCP_TLS,
        username: config.OPENRELAY_TURN_USERNAME,
        credential: config.OPENRELAY_TURN_CREDENTIAL,
      },
    ] : []),
    ...(config.USE_GOOGLE_STUN === 'true' ? [
      // Google STUN servers
      { urls: 'stun:stun.l.google.com:19302' },
      { urls: 'stun:stun1.l.google.com:19302' },
    ] : []),
    ...(config.USE_SELF_TURN === 'true' ? [
      // 自建TURN server
      {
        urls: [
          'turn:47.99.78.235:3478?transport=udp',
          'turn:47.99.78.235:3478?transport=tcp',
          'turn:47.99.78.235:5349'
        ],
        username: 'root',
        credential: '123456',
        credentialType: 'password'
      }
    ] : [])
  ],
  iceCandidatePoolSize: 10,
  bundlePolicy: 'max-bundle',
  rtcpMuxPolicy: 'require',
  iceTransportPolicy: 'all'
} as RTCConfiguration;

// 打印配置信息
console.log('[WebRTC Configuration] ICE服务器配置:', {
  总服务器数量: configuration.iceServers?.length ?? 0,
  OpenRelay服务器: configuration.iceServers?.slice(0, 5) ?? [],
  Google_STUN启用状态: config.USE_GOOGLE_STUN,
  自建TURN启用状态: config.USE_SELF_TURN,
  完整服务器列表: configuration.iceServers ?? []
});

const createKeepAliveChannel = (pc: RTCPeerConnection) => {
  // 创建保活数据通道
  keepAliveChannel = pc.createDataChannel('keepalive', {
    ordered: true,
    negotiated: true,
    id: 0
  });

  // 添加更多状态日志
  console.log('[保活通道] 创建完成:', {
    readyState: keepAliveChannel.readyState,  // 初始状态应该是 'connecting'
    时间戳: new Date().toISOString()
  });

  keepAliveChannel.onopen = () => {
    console.log('[保活通道] 已打开:', {
      readyState: keepAliveChannel?.readyState,
      ICE状态: pc.iceConnectionState,
      连接状态: pc.connectionState,
      时间戳: new Date().toISOString()
    });
  };

  keepAliveChannel.onclose = () => {
    console.log('[保活通道] 已关闭:', {
      ICE状态: pc.iceConnectionState,
      连接状态: pc.connectionState,
      时间戳: new Date().toISOString()
    });
    keepAliveChannel = null;
  };

  keepAliveChannel.onerror = (error) => {
    console.error('[保活通道] 错误:', {
      error,
      ICE状态: pc.iceConnectionState,
      连接状态: pc.connectionState,
      时间戳: new Date().toISOString()
    });
  };
};

// 保活机制
const startKeepAlive = (pc: RTCPeerConnection) => {
  if (keepAliveInterval) {
    clearInterval(keepAliveInterval);
  }

  keepAliveInterval = setInterval(async () => {
    try {
      if (pc.connectionState === 'connected' && 
          keepAliveChannel && 
          keepAliveChannel.readyState === 'open') {
        
        keepAliveChannel.send(new Uint8Array([1]));
        
        // 获取连接统计
        const stats = await pc.getStats();
        let bytesReceived = 0;
        let bytesSent = 0;
        
        stats.forEach(report => {
          if (report.type === 'transport') {
            bytesReceived += report.bytesReceived || 0;
            bytesSent += report.bytesSent || 0;
          }
        });
        
        console.log('[保活] 发送保活包成功:', {
          时间戳: new Date().toISOString(),
          通道状态: keepAliveChannel.readyState,
          连接状态: pc.connectionState,
          数据统计: {
            接收字节: bytesReceived,
            发送字节: bytesSent
          }
        });
      } else {
        console.warn('[保活] 连接状态异常:', {
          connectionState: pc.connectionState,
          channelState: keepAliveChannel?.readyState
        });
      }
    } catch (error) {
      console.warn('[保活] 保活检查失败:', error);
    }
  }, KEEPALIVE_INTERVAL);
};

const stopKeepAlive = () => {
  if (keepAliveInterval) {
    clearInterval(keepAliveInterval);
    keepAliveInterval = null;
  }

  if (keepAliveChannel) {
    keepAliveChannel.close();
    keepAliveChannel = null;
  }
};

// 设置连接监听器
const setupPeerConnectionListeners = (
  pc: RTCPeerConnection,
  dispatch: any,
  payload: any
) => {
  // 添加初始状态日志
  console.log('[PeerConnection] 初始状态:', {
    ICE状态: pc.iceConnectionState,
    连接状态: pc.connectionState,
    信令状态: pc.signalingState,
    时间戳: new Date().toISOString()
  });

  // 添加 ontrack 监听器 (由远端的 addTrack() 触发)
  pc.ontrack = (event) => {
    console.log('[pc.ontrack] 收到远程轨道:', {
        类型: event.track.kind,
        流数量: event.streams.length,
        轨道状态: event.track.readyState,
        ICE状态: pc.iceConnectionState,
        时间戳: new Date().toISOString()
    });

    // 只在 ICE 连接就绪时设置远程流
    if (pc.iceConnectionState === 'connected' || pc.iceConnectionState === 'completed') {
      dispatch(webrtcSlice.actions.setRemoteStream(event.streams[0]));
    } else {
      console.log('[pc.ontrack] 等待 ICE 连接就绪后再设置远程流');
      // 监听 ICE 状态变化，在连接成功时设置流
      const iceHandler = () => {
        if (pc.iceConnectionState === 'connected' || pc.iceConnectionState === 'completed') {
          dispatch(webrtcSlice.actions.setRemoteStream(event.streams[0]));
          pc.removeEventListener('iceconnectionstatechange', iceHandler);
        }
      };
      pc.addEventListener('iceconnectionstatechange', iceHandler);
    }
    
    if (event.track.readyState === 'ended') {
      console.warn('[pc.ontrack] 收到已结束的轨道:', event.track.kind);
    }
  };

  // ICE连接状态变化监听（设置远程描述被触发）
  pc.oniceconnectionstatechange = () => {
    const state = pc.iceConnectionState;
    console.log('[ICE状态改变] ICE连接状态变化:', {
      状态: state,
      时间戳: new Date().toISOString(),
      保活通道状态: keepAliveChannel?.readyState,
      ICE候选者数量: pc.canTrickleIceCandidates,
      本地描述: pc.localDescription?.type,
      远程描述: pc.remoteDescription?.type
    });
    
    switch(state) {
      case 'checking':
        console.log('[ICE状态改变] 正在尝试建立连接...');
        break;
      case 'connected':
        console.log('[ICE状态改变] 连接成功建立');
        startKeepAlive(pc);
        break;
      case 'disconnected':
        // console.warn('[ICE状态改变] 连接暂时断开 - 尝试重新协商');
        console.warn('[ICE状态改变] 连接暂时断开');
        // 尝试重新协商
        // if (keepAliveChannel?.readyState === 'open') {
        //   if (pc.remoteDescription) {
        //     pc.restartIce();
        //     console.log('[ICE状态改变] 已触发ICE重启');
        //     // 如果5秒内没有恢复，尝试重新创建offer
        //     setTimeout(() => {
        //       if (pc.iceConnectionState === 'disconnected') {
        //         console.log('[ICE状态改变] ICE重启超时，尝试重新创建offer');
        //         const { roomId, userId } = payload;
        //         dispatch(webrtcSlice.actions.createOffer({ roomId, userId }));
        //       }
        //     }, 5000);
        //   }
        // }
        break;
      case 'failed':
        // console.error('[ICE状态改变] 连接失败 - 尝试重置');
        console.error('[ICE状态改变] 连接失败');
        // const { roomId, userId } = payload;
        // // 清理现有连接
        // if (keepAliveChannel) {
        //   keepAliveChannel.close();
        //   keepAliveChannel = null;
        // }
        // if (keepAliveInterval) {
        //   clearInterval(keepAliveInterval);
        //   keepAliveInterval = null;
        // }
        // pc.close();
        // // 重新初始化
        // dispatch(webrtcSlice.actions.initialize({ roomId, userId }));
        break;
    }
  };

  // ICE候选者收集状态监听
  pc.onicegatheringstatechange = (event) => {
    console.log('[ICE收集状态改变] ICE收集状态:', {
      状态: pc.iceGatheringState,
      时间戳: new Date().toISOString(),
      连接状态: pc.iceConnectionState,
    });
  };

  // 添加ICE候选者监听（设置本地描述被触发）
  pc.onicecandidate = (event) => {
    console.log('ICE Candidate event:', event);
    console.log('[ICE候选者监听] 新的ICE候选者:', {
      类型: event.candidate?.type,
      协议: event.candidate?.protocol,
      地址: event.candidate?.address,
      端口: event.candidate?.port,
      远程描述类型: pc.remoteDescription?.type,
      时间戳: new Date().toISOString()
    });

    if (event.candidate && event.candidate.candidate) {
      const { roomId, userId } = payload;
      
      if (!pc.remoteDescription) {
        // 缓存候选者而不是丢弃
        console.log('[ICE候选者监听] 没有远程描述，暂存ICE候选者：等待远程连接');
        pendingIceCandidates.push(event.candidate);
        return;
      }
      
      dispatch(sendConnectionMessage({
        type: 'ice-candidate',
        roomId,
        userId,
        时间戳: new Date().toISOString(),
        candidate: event.candidate.toJSON()
      }));
    } else {
      // event.candidate 存在但 candidate 字符串为空，表示收集完成
      console.log('[ICE候选者监听] ICE候选者收集完成', {
        时间戳: new Date().toISOString(),
        收集状态: pc.iceGatheringState,
        最后事件: event.candidate  // 记录最后的事件对象
      });
    }
  };

  // 修改连接状态处理
  pc.onconnectionstatechange = () => {
    const stats = {
      状态: pc.connectionState,
      时间戳: new Date().toISOString(),
      数据通道状态: keepAliveChannel?.readyState,
      ICE状态: pc.iceConnectionState,
      ICE收集状态: pc.iceGatheringState,
      信令状态: pc.signalingState,
      视频轨道状态: pc.getTransceivers()
        .filter(t => t.sender.track?.kind === 'video')
        .map(t => ({
          方向: t.direction,
          当前状态: t.currentDirection,
          轨道状态: t.sender.track?.enabled
        }))
    };
    
    console.log('[pc连接状态改变] 连接状态变化:', stats);

    if (pc.connectionState === 'disconnected') {
      console.log('[pc连接状态改变] 连接断开，尝试重置...');
      const { roomId, userId } = payload;
      // 重新初始化
      dispatch(webrtcSlice.actions.initialize({ roomId, userId })); 
    }
    
    if (pc.connectionState === 'failed') {
      console.log('[pc连接状态改变] 连接失败，尝试重置...');
      const { roomId, userId } = payload;
      // 重新初始化
      setTimeout(() => {
        dispatch(webrtcSlice.actions.initialize({ roomId, userId }));
      }, 1000);
    }
  };

  // 协商需求监听
  pc.onnegotiationneeded = (event) => {
    console.log('[协商需求监听] 需要重新协商:', {
      时间戳: new Date().toISOString(),
      ICE状态: pc.iceConnectionState,
      连接状态: pc.connectionState,
      数据通道状态: keepAliveChannel?.readyState,

    });
  };

  // 添加更多的状态监控
  pc.onsignalingstatechange = () => {
    console.log('[信令诊断] 信令状态变化:', {
      信令状态: pc.signalingState,
      ICE状态: pc.iceConnectionState,
      连接状态: pc.connectionState,
      远程描述: pc.remoteDescription?.type,
      本地描述: pc.localDescription?.type,
      时间戳: new Date().toISOString()
    });
  };
};

// WebRTC中间件
const webrtcMiddleware: Middleware = store => next => async (action: any) => {  
  const dispatch = store.dispatch;

  if (action.type === webrtcSlice.actions.initialize.type) {
    try {
      // 清空ICE候选者缓存
      pendingIceCandidates = [];
      
      // 清理旧连接和保活机制
      stopKeepAlive();
      if (peerConnection) {
        peerConnection.close();
        peerConnection = null;
      }

      const stream = store.getState().webrtc.localStream;
      if (!stream) {
        throw new Error('No local stream available');
      }

      // 创建新连接
      peerConnection = new RTCPeerConnection(configuration);
      setupPeerConnectionListeners(peerConnection, dispatch, action.payload);
      dispatch(webrtcSlice.actions.setPeerConnection(peerConnection));
      createKeepAliveChannel(peerConnection);

      // 添加所有轨道
      stream.getTracks().forEach((track: MediaStreamTrack) => {
        try {
          console.log('[WebRTC] Adding track:', {
            kind: track.kind,
            enabled: track.enabled,
            readyState: track.readyState
          });
          peerConnection?.addTrack(track, stream);
        } catch (error) {
          console.warn(`[WebRTC] Failed to add ${track.kind} track:`, error);
          // 继续处理其他轨道，不要中断整个过程
        }
      });

      dispatch(webrtcSlice.actions.setStatus('connected'));
    } catch (error) {
      console.error('[WebRTC] Initialize Error:', error);
      dispatch(webrtcSlice.actions.setError((error as Error).message));
    }
  }

  else if (action.type === webrtcSlice.actions.createOffer.type) {
    try {
      if (!peerConnection) {
        throw new Error('No peer connection available');
      }

      const offer = await peerConnection.createOffer();
      await peerConnection.setLocalDescription(offer);
      console.log('设置本地描述完成 offer:', offer);

      const { roomId, userId } = action.payload;
      dispatch(sendConnectionMessage({
        type: 'offer',
        roomId,
        userId,
        offer
      }));

    } catch (error) {
      dispatch(webrtcSlice.actions.setError((error as Error).message));
    }
  }

  else if (action.type === webrtcSlice.actions.handleOffer.type) {
    try {
      if (!peerConnection) {
        throw new Error('No peer connection available');
      }

      const { roomId, userId, offer } = action.payload;
      
      // 如果当前连接状态不好，先重置连接
      if (peerConnection.connectionState === 'failed' || 
          peerConnection.iceConnectionState === 'failed') {
        console.log('[Offer处理] 检测到连接状态异常，重置连接');
        peerConnection.close();
        dispatch(webrtcSlice.actions.initialize({ roomId, userId }));
        return;
      }

      await peerConnection.setRemoteDescription(new RTCSessionDescription(offer));
      console.log('设置远程描述完成 offer:', offer);
      const answer = await peerConnection.createAnswer();
      await peerConnection.setLocalDescription(answer);
      console.log('设置本地描述完成 answer:', answer);


      dispatch(sendConnectionMessage({
        type: 'answer',
        roomId,
        userId,
        answer
      }));

    } catch (error) {
      dispatch(webrtcSlice.actions.setError((error as Error).message));
    }
  }

  else if (action.type === webrtcSlice.actions.handleAnswer.type) {
    try {
      if (!peerConnection) {
        throw new Error('No peer connection available');
      }

      const { answer } = action.payload;
      await peerConnection.setRemoteDescription(new RTCSessionDescription(answer));
      console.log('设置远程描述完成 answer:', answer);

      // 发送所有缓存的候选者
      const { roomId, userId } = action.payload;
      pendingIceCandidates.forEach(candidate => {
        dispatch(sendConnectionMessage({
          type: 'ice-candidate',
          roomId,
          userId,
          时间戳: new Date().toISOString(),
          candidate: candidate.toJSON()
        }));
      });
      // 清空ICE候选者缓存
      pendingIceCandidates = [];

    } catch (error) {
      dispatch(webrtcSlice.actions.setError((error as Error).message));
    }
  }

  else if (action.type === webrtcSlice.actions.handleIceCandidate.type) {
    try {
      if (!peerConnection) {
        throw new Error('No peer connection available');
      }

      const { candidate } = action.payload;
      await peerConnection.addIceCandidate(new RTCIceCandidate(candidate));

    } catch (error) {
      dispatch(webrtcSlice.actions.setError((error as Error).message));
    }
  }

  else if (action.type === webrtcSlice.actions.cleanup.type) {
    try {
      // 停止保活机制
      stopKeepAlive();

      // 停止本地流的所有轨道
      if (store.getState().webrtc.localStream) {
        store.getState().webrtc.localStream.getTracks().forEach((track: MediaStreamTrack) => {
          track.stop();
        });
      }

      // 停止远程流的所有轨道
      if (store.getState().webrtc.remoteStream) {
        store.getState().webrtc.remoteStream.getTracks().forEach((track: MediaStreamTrack) => {
          track.stop();
        });
      }    

      // 确保所有轨道都被正确停止
      if (peerConnection) {
        peerConnection.getSenders().forEach(sender => {
          if (sender.track) {
            sender.track.stop();
          }
        });
        peerConnection.getReceivers().forEach(receiver => {
          if (receiver.track) {
            receiver.track.stop();
          }
        });
        peerConnection.close();
        peerConnection = null;
      }

      // 清空ICE候选者缓存
      pendingIceCandidates = [];
      // 重置所有状态
      dispatch(webrtcSlice.actions.setLocalStream(null));
      dispatch(webrtcSlice.actions.setRemoteStream(null));
      dispatch(webrtcSlice.actions.setStatus('disconnected'));
    } catch (error) {
      console.error('[Cleanup] Error:', error);
    }
  } else if (action.type === webrtcSlice.actions.cleanupRemoteStream.type) {
    try {
      const { roomId, userId } = action.payload;
      // 停止远程流的所有轨道
      if (store.getState().webrtc.remoteStream) {
        store.getState().webrtc.remoteStream.getTracks().forEach((track: MediaStreamTrack) => {
          track.stop();
        });
      }
      dispatch(webrtcSlice.actions.setRemoteStream(null));
      dispatch(webrtcSlice.actions.setStatus('disconnected'));
      dispatch(webrtcSlice.actions.initialize({ roomId, userId }));
    } catch (error) {
      console.error('[WebRTC] Partial cleanup failed:', error);
      dispatch(webrtcSlice.actions.setError((error as Error).message));
    }
  }

  return next(action);
};

export default webrtcMiddleware;