import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ConfirmDialogState {
  isOpen: boolean;
  title?: string;
  message?: string;
  onConfirm?: () => void;
  onCancel?: () => void;
}

interface ConfirmDialogAwaitState {
  isOpen: boolean;
  title?: string;
  message?: string;
  resolvePromise?: (value: boolean) => void;
}

interface UiState {
  confirmDialog: ConfirmDialogState;
  confirmDialogAwait: ConfirmDialogAwaitState;
  // ... 其他 UI 状态
}

const initialState: UiState = {
  confirmDialog: {
    isOpen: false,
    title: '',
    message: '',
    onConfirm: () => {},
    onCancel: () => {},
  },
  confirmDialogAwait: {
    isOpen: false,
    title: '',
    message: '',
    resolvePromise: () => {},
  },
  // ... 其他初始状态
};

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setConfirmDialog: (state, action: PayloadAction<Partial<ConfirmDialogState>>) => {
      state.confirmDialog = {
        ...state.confirmDialog,
        ...action.payload,
      };
    },
    setConfirmDialogAwait: (state, action: PayloadAction<Partial<ConfirmDialogAwaitState>>) => {
      state.confirmDialogAwait = {
        ...state.confirmDialogAwait,
        ...action.payload,
      };
    },
  },
});

export const { setConfirmDialog, setConfirmDialogAwait } = uiSlice.actions;
export default uiSlice.reducer; 