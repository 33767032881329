import React, { useState, useEffect } from 'react';
import './ValidateCaptcha.css';
import { useTranslation } from 'react-i18next';
import { setConfirmDialog } from '../store/slices/uiSlice';
import { useDispatch } from 'react-redux';
import { useAppDispatch } from '../store/hooks';

interface ValidateCaptchaProps {
  onSuccess: () => void;
  onClose: () => void;
}

const ValidateCaptcha: React.FC<ValidateCaptchaProps> = ({ onSuccess, onClose }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const directions = ['⬆️', '⬇️', '⬅️', '➡️'] as const;
  const [direction] = useState<typeof directions[number]>(
    directions[Math.floor(Math.random() * 4)]
  );
  const [activeButton, setActiveButton] = useState<string | null>(null);

  const handleClick = (selectedDirection: string) => {
    setActiveButton(selectedDirection);
    
    // 添加短暂延迟以显示按压效果
    setTimeout(() => {
      if (selectedDirection === direction) {
        onSuccess();
      } else {
        setTimeout(() => {
          // alert((t as any)('validationFailed'));
          dispatch(setConfirmDialog({
            isOpen: true,
            title: (t as any)('confirm'),
            message: (t as any)('validationFailed'),
            onConfirm: () => {
              setActiveButton(null);
            },
            onCancel: () => {
              onClose();
              setActiveButton(null);
            }
          })); 
        }, 100);
      }
      setActiveButton(null);
    }, 150);
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      let selectedDirection = '';
      
      switch (event.key) {
        case 'ArrowUp':
          selectedDirection = '⬆️';
          break;
        case 'ArrowDown':
          selectedDirection = '⬇️';
          break;
        case 'ArrowLeft':
          selectedDirection = '⬅️';
          break;
        case 'ArrowRight':
          selectedDirection = '➡️';
          break;
        case 'Escape':
          onClose();
          return;
      }

      if (selectedDirection) {
        handleClick(selectedDirection);
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [direction, onSuccess, onClose]);

  const renderButton = (dir: string) => (
    <button 
      onClick={() => handleClick(dir)} 
      className={`direction-button ${activeButton === dir ? 'active' : ''}`}
      aria-label={`Select ${dir}`}
    >
      {dir}
    </button>
  );

  return (
    <div 
      className="slider-modal" 
      onClick={(e) => e.target === e.currentTarget && onClose()}
      role="dialog"
      aria-modal="true"
      aria-label="Direction validation"
    >
      <div className="direction-container">
        <p className="direction-tip">
          {(t as any)('clickDirection').split('{direction}')[0]}
          <span className="direction-highlight">{direction}</span>
          {(t as any)('clickDirection').split('{direction}')[1]}
        </p>
        <div className="direction-buttons">
          {renderButton('⬆️')}
          <div className="middle-row">
            {renderButton('⬅️')}
            {renderButton('➡️')}
          </div>
          {renderButton('⬇️')}
        </div>
      </div>
    </div>
  );
};

export default ValidateCaptcha; 