import { useNavigate } from "react-router-dom";
import { UserType } from "../types/user";
import { useAppSelector } from "../store/hooks";

/**
 * 返回首页的 Hook
 */
export const useGoToHome = (): () => void => {
    const navigate = useNavigate();
    const { userType } = useAppSelector(state => state.user);

    return () => {
        if (userType === UserType.ADMIN) {
            navigate('/admin');
        }
        if (userType === UserType.TEACHER) {
            navigate('/teacher');
        }
        if (userType === UserType.STUDENT) {
            navigate('/');
        }
    }
}