import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { downloadBook } from '../services/downloadService';
import api from '../utils/ApiClient';
import './BookManagement.css';

interface Book {
    id: string;
    title: string;
    author: string;
    imgUrl: string;
    // Add more book properties as needed
}

interface BookManagementProps {
    isOpen: boolean;
    onClose: () => void;
}

const BookManagement: React.FC<BookManagementProps> = ({ isOpen, onClose }) => {
    const { t } = useTranslation();
    const [books, setBooks] = useState<Book[]>([]);
    const [loading, setLoading] = useState(true);
    const [downloadingBooks, setDownloadingBooks] = useState<Set<string>>(new Set());
    const [downloadProgress, setDownloadProgress] = useState<Record<string, number>>({});

    useEffect(() => {
        if (isOpen) {
            // TODO: Replace with actual API call
            fetchBooks();
        }
    }, [isOpen]);

    const fetchBooks = async () => {
        try {

            const response = await api.get('/books');
            const books = response.data;
            setBooks(books);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching books:', error);
            setLoading(false);
        }
    };

    const handleDownload = async (bookId: string, fileName: string) => {
        try {
            // 先触发文件选择器
            const handle = await window.showSaveFilePicker({
                suggestedName: fileName,
                types: [{
                    description: 'PDF Document',
                    accept: {'application/pdf': ['.pdf']}
                }]
            });

            setDownloadingBooks(prev => new Set(prev).add(bookId));
            setDownloadProgress(prev => ({ ...prev, [bookId]: 0 }));
            
            // 然后开始下载
            await downloadBook(bookId, handle, (progress) => {
                setDownloadProgress(prev => ({ ...prev, [bookId]: progress }));
            });
            
        } catch (error) {
            if (error instanceof Error && error.name !== 'AbortError') { // 忽略用户取消的情况
                alert(t('downloadFailed'));
            }
        } finally {
            setDownloadingBooks(prev => {
                const newSet = new Set(prev);
                newSet.delete(bookId);
                return newSet;
            });
            setDownloadProgress(prev => {
                const newProgress = { ...prev };
                delete newProgress[bookId];
                return newProgress;
            });
        }
    };

    if (!isOpen) return null;

    return (
        <div className="book-management-modal">
            <div className="book-management-content">
                <div className="book-management-header">
                    <h2>{t('title')}</h2>
                    <button className="close-button" onClick={onClose}>&times;</button>
                </div>
                
                <div className="book-list">
                    {loading ? (
                        <div className="loading">加载中...</div>
                    ) : (
                        books.map(book => (
                            <div key={book.id} className="book-item">
                                <img 
                                    src={'https://via.placeholder.com/150'}
                                    alt={book.title} 
                                    className="book-cover"
                                />
                                <div className="book-info">
                                    <h3>{book.title}</h3>
                                    <p>{book.author}</p>
                                    <button 
                                        className="download-button"
                                        onClick={() => handleDownload(book.id, book.title)}
                                        disabled={downloadingBooks.has(book.id)}
                                    >
                                        {downloadingBooks.has(book.id) 
                                            ? `${t('downloading')} ${downloadProgress[book.id] || 0}%` 
                                            : t('download')}
                                    </button>
                                </div>
                            </div>
                        ))
                    )}
                </div>
            </div>
        </div>
    );
};

export default BookManagement; 