import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useAwaitConfirmDialog } from '../hook/useAwaitConfirmDialog';
import { RootState } from '../store';
import api from '../utils/ApiClient';
import styles from './ChangePassword.module.css';

export default function ChangePassword() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { userId } = useSelector((state: RootState) => state.user);
    const [formData, setFormData] = useState({
        currentPassword: '',
        newPassword: '',
        confirmPassword: '',
    });
    const [error, setError] = useState('');
    const { showConfirmDialogAwait } = useAwaitConfirmDialog();

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setError('');

        // 验证新密码
        if (formData.newPassword.length < 6) {
            showConfirmDialogAwait(t('prompt'), (t as any)('passwordTooShort'));
            return;
        }

        if (formData.newPassword !== formData.confirmPassword) {
            showConfirmDialogAwait(t('prompt'), (t as any)('passwordsDoNotMatch'));
            return;
        }

        try {
            await api.put(`/users/${userId}/password`, formData);
            navigate('/profile');
        } catch (err: any) {
            showConfirmDialogAwait(t('prompt'), err.response?.data || t('changePasswordError'));
        }
    };

    return (
        <>
            <div className={styles['change-password-container']}>
                <div className={styles['change-password-card']}>
                    <h2>{(t as any)('changePassword')}</h2>
                    {error && <div className="error-message">{error}</div>}

                    <form className={styles['change-password-form']}>
                        <div className={styles['form-group']}>
                            <label>{(t as any)('currentPassword')}</label>
                            <input
                                type="password"
                                name="currentPassword"
                                value={formData.currentPassword}
                                onChange={handleChange}
                            />
                        </div>

                        <div className={styles['form-group']}>
                            <label>{(t as any)('newPassword')}</label>
                            <input
                                type="password"
                                name="newPassword"
                                value={formData.newPassword}
                                onChange={handleChange}
                            />
                        </div>

                        <div className={styles['form-group']}>
                            <label>{(t as any)('confirmNewPassword')}</label>
                            <input
                                type="password"
                                name="confirmPassword"
                                value={formData.confirmPassword}
                                onChange={handleChange}
                            />
                        </div>
                    </form>

                    <div className={styles['change-password-footer']}>
                        <div className={styles['button-group']}>
                            <button
                                className={styles['cancel-button']}
                                onClick={() => navigate('/profile')}
                            >
                                {(t as any)('cancel')}
                            </button>
                            <button
                                className={styles['submit-button']}
                                onClick={handleSubmit}
                            >
                                {(t as any)('confirm')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
