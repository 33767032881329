import './AdminSettleHome.css';
import { useNavigate } from 'react-router-dom';

const AdminSettleHome = () => {
  const navigate = useNavigate();

  return (
    <div className="settle-container p-6">
      <div className="button-container">
        <button
          onClick={() => navigate('/adminSettleTeacher')}
          className="settle-button teacher-button"
        >
          teacher settle record
        </button>
        <button
          onClick={() => navigate('/adminSettleSeller')}
          className="settle-button seller-button"
        >
          销售结算记录
        </button>
      </div>
    </div>
  );
};

export default AdminSettleHome;