import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RoomState } from '../../types/room';

const initialState: RoomState = {
  roomId: null,
};

export const roomSlice = createSlice({
  name: 'room',
  initialState,
  reducers: {
    setRoomInfo: (state, action: PayloadAction<{ roomId: string}>) => {
      state.roomId = action.payload.roomId;
    },
    reset: () => initialState,
  }
});

export const { 
  setRoomInfo,
  reset,
} = roomSlice.actions;

export default roomSlice.reducer;