import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../store/hooks';
import { SettleStatus } from '../types/settle';
import api from '../utils/ApiClient';
import './AdminSettleSeller.css';

interface SellerSettleRecord {
  id: string;
  userId: string;
  settledAmount: number;
  incomeAmount: number;
  settleRate: number;
  status: string;
  confirmBy: string;
  confirmAt: string;
  createdAt: string;
  updatedAt: string;
}

const AdminSettleSeller = () => {
  const { t } = useTranslation();
  const [records, setRecords] = useState<SellerSettleRecord[]>([]);
  const { userId } = useAppSelector(state => state.user);

  useEffect(() => {
    // TODO: 从API获取数据
    fetchSellerSettleRecords();
  }, []);

  const fetchSellerSettleRecords = async () => {
    try {
      const response = await api.get('/admin/settleDetail/seller');
      const data = response.data;
      setRecords(data);
    } catch (error) {
      console.error('获取销售结算记录失败:', error);
    }
  };

  const handleConfirmSettle = async (recordId: string) => {
    try {
      await api.post(`/admin/settleDetail/seller/${recordId}/confirm/${userId}`);
      await fetchSellerSettleRecords(); // 刷新数据
    } catch (error: any) {
      console.error('确认结算失败:', error);
      alert(error.response.data || '确认结算失败');
    }
  };

  const handleCancelSettle = async (recordId: string) => {
    try {
      await api.post(`/admin/settleDetail/seller/${recordId}/cancel/${userId}`);
      await fetchSellerSettleRecords(); // 刷新数据
    } catch (error: any) {
      console.error('撤销结算失败:', error);
      alert(error.response.data || '撤销结算失败');
    }
  };

  return (
    <div className="settle-seller-list-container">
      <div className="settle-seller-table-container">
        <table className="settle-seller-table">
          <thead>
            <tr>
              <th>结算ID</th>
              <th>销售ID</th>
              <th>结算金额(元)</th>
              <th>收入金额(元)</th>
              <th>收入费率(%)</th>
              <th>状态</th>
              <th>确认人</th>
              <th>确认时间</th>
              <th>创建时间</th>
              <th>更新时间</th>
              <th>操作</th>
            </tr>
          </thead>
          <tbody>
            {records.map((record) => (
              <tr key={record.id}>
                <td data-label="结算ID">{record.id}</td>
                <td data-label="销售ID">{record.userId}</td>
                <td data-label="结算金额">{record.settledAmount.toFixed(2)}</td>
                <td data-label="收入金额">{record.incomeAmount.toFixed(2)}</td>
                <td data-label="收入费率">{(record.settleRate * 100).toFixed(2)}%</td>
                <td data-label="状态">
                  <span className={`settle-seller-status-badge settle-seller-status-${record.status.toLowerCase()}`}>
                    {t(`settleStatusDist.${record.status}`)}
                  </span>
                </td>
                <td data-label="确认人">{record.confirmBy}</td>
                <td data-label="确认时间">{new Date(record.confirmAt).toLocaleString()}</td>
                <td data-label="创建时间">{new Date(record.createdAt).toLocaleString()}</td>
                <td data-label="更新时间">{new Date(record.updatedAt).toLocaleString()}</td>
                <td data-label="操作">
                  {record.status === SettleStatus.PADDING && (
                    <div className="button-group">
                      <button
                        className="settle-button green-button"
                        onClick={() => handleConfirmSettle(record.id)}
                      >
                        确认结算
                      </button>
                      <button
                        className="settle-button blue-button"
                        onClick={() => handleCancelSettle(record.id)}
                      >
                        撤销结算
                      </button>
                    </div>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AdminSettleSeller; 