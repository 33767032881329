import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../store/hooks';
import { SettleStatus } from '../types/settle';
import api from '../utils/ApiClient';
import './AdminSettleTeacher.css';

interface TeacherSettleRecord {
  id: string;
  userId: string;
  recAccountNo: string;
  settledTime: number;
  incomeAmount: number;
  settleRate: number;
  status: string;
  confirmBy: string;
  confirmAt: string;
  createdAt: string;
  updatedAt: string;
}

const AdminSettleTeacher = () => {
  const { t } = useTranslation();
  const [records, setRecords] = useState<TeacherSettleRecord[]>([]);
  const { userId } = useAppSelector(state => state.user);

  useEffect(() => {
    // TODO: 从API获取数据
    fetchTeacherSettleRecords();
  }, []);

  const fetchTeacherSettleRecords = async () => {
    try {
      const response = await api.get('/admin/settleDetail/teacher');
      const data = response.data;
      setRecords(data);
    } catch (error: any) {
      console.error('获取教师结算记录失败:', error);
      alert(error.response.data || '获取教师结算记录失败');
    }
  };

  const handleConfirmSettle = async (recordId: string) => {
    try {
      await api.post(`/admin/settleDetail/teacher/${recordId}/confirm/${userId}`);
      await fetchTeacherSettleRecords(); // 刷新数据
    } catch (error: any) {
      console.error('确认结算失败:', error);
      alert(error.response.data || '确认结算失败');
    }
  };

  const handleCancelSettle = async (recordId: string) => {
    try {
      await api.post(`/admin/settleDetail/teacher/${recordId}/cancel/${userId}`);
      await fetchTeacherSettleRecords(); // 刷新数据
    } catch (error: any) {
      console.error('撤销结算失败:', error);
      alert(error.response.data || '撤销结算失败');
    }
  };

  return (
    <div className="settle-teacher-list-container">
      <div className="settle-teacher-table-container">
        <table className="settle-teacher-table">
          <thead>
            <tr>
              <th>settle ID</th>
              <th>user ID</th>
              <th>rec account</th>
              <th>settled time</th>
              <th>income amount</th>
              <th>settle rate</th>
              <th>status</th>
              <th>confirm by</th>
              <th>confirm at</th>
              <th>created at</th>
              <th>updated at</th>
              <th>操作</th>
            </tr>
          </thead>
          <tbody>
            {records.map((record) => (
              <tr key={record.id}>
                <td data-label="settle ID">{record.id}</td>
                <td data-label="user ID">{record.userId}</td>
                <td data-label="rec account">{record.recAccountNo}</td>
                <td data-label="settled time">{record.settledTime}</td>
                <td data-label="income amount">{record.incomeAmount.toFixed(2)}</td>
                <td data-label="settle rate">{record.settleRate.toFixed(2)}</td>
                <td data-label="status">
                  <span className={`settle-teacher-status-badge settle-teacher-status-${record.status.toLowerCase()}`}>
                    {t(`settleStatusDist.${record.status}`)}
                  </span>
                </td>
                <td data-label="confirm by">{record.confirmBy}</td>
                <td data-label="confirm at">{new Date(record.confirmAt).toLocaleString()}</td>
                <td data-label="created at">{new Date(record.createdAt).toLocaleString()}</td>
                <td data-label="updated at">{new Date(record.updatedAt).toLocaleString()}</td>
                <td data-label="操作">
                  {record.status === SettleStatus.PADDING && (
                    <div className="button-group">
                      <button
                        className="settle-button green-button"
                        onClick={() => handleConfirmSettle(record.id)}
                      >
                        confirm
                      </button>
                      <button
                        className="settle-button blue-button"
                        onClick={() => handleCancelSettle(record.id)}
                      >
                        cancel
                      </button>
                    </div>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AdminSettleTeacher; 