import { FC, useEffect, useState } from 'react';
import api from '../utils/ApiClient';
import AppointmentTime from './AppointmentTime';
import styles from './TeacherIntro.module.css';

interface TeacherIntroProps {
  teacher: {
    id: string;
    firstName: string;
    lastName: string;
    avatar: string;
  };
  onClose: () => void;
}

const TeacherIntro: FC<TeacherIntroProps> = ({ teacher, onClose }) => {
  const [teacherIntro, setTeacherIntro] = useState('');

  useEffect(() => {
    fetchTeacherIntro();
  }, [teacher.id]);

  const fetchTeacherIntro = async () => {
    try {
      const response = await api.get(`/users/${teacher.id}`);
      setTeacherIntro(response.data.briefIntro || '暂无简介');
    } catch (error) {
      console.error('Failed to fetch teacher intro:', error);
      setTeacherIntro('暂无简介');
    }
  };

  return (
    <div 
      className={styles['modal-overlay']} 
      onClick={(e) => {
        if (e.target === e.currentTarget) {
          onClose();
        }
      }}
    >
      <div className={styles['modal-content']}>
        <button className={styles['close-button']} onClick={onClose}>×</button>
        <div className={styles['teacher-header']}>
          <div className={styles['teacher-info']}>
            <p className={styles['introduction']}>
              {teacherIntro || "暂无介绍"}
            </p>
          </div>
          <img 
            src={teacher.avatar || "https://api.dicebear.com/7.x/thumbs/svg?seed=6&radius=50"} 
            alt={`${teacher.firstName} ${teacher.lastName}`} 
            className={styles['teacher-avatar']}
          />
        </div>
        <AppointmentTime teacherId={teacher.id} />
      </div>
    </div>
  );
};

export default TeacherIntro; 