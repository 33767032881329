import React, { useRef, useState, useEffect } from 'react';
import { FaPen, FaEraser, FaUndo, FaSave, FaTimes, FaTrash } from 'react-icons/fa';
import styles from './DrawingCanvas.module.css';
import { useTranslation } from 'react-i18next';

interface DrawingCanvasProps {
  isOpen: boolean;
  onClose: () => void;
}

const DrawingCanvas: React.FC<DrawingCanvasProps> = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [context, setContext] = useState<CanvasRenderingContext2D | null>(null);
  const [isDrawing, setIsDrawing] = useState(false);
  const [tool, setTool] = useState<'pen' | 'eraser'>('pen');
  const [color, setColor] = useState('#000000');
  const [lineWidth, setLineWidth] = useState(2);
  const [undoStack, setUndoStack] = useState<ImageData[]>([]);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });

  useEffect(() => {
    if (canvasRef.current) {
      const canvas = canvasRef.current;
      // 设置画布实际尺寸
      canvas.width = 800;
      canvas.height = 600;
      
      const ctx = canvas.getContext('2d', { willReadFrequently: true });
      if (ctx) {
        // 设置初始画笔属性
        ctx.strokeStyle = tool === 'eraser' ? '#ffffff' : color;
        ctx.lineWidth = lineWidth;
        ctx.lineCap = 'round';
        ctx.lineJoin = 'round';
        setContext(ctx);

        // 初始化时保存一个空白画布状态用于撤销
        const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        setUndoStack([imageData]);
      }
    }
  }, [isOpen]); // 当画布打开时初始化

  // 添加一个新的 useEffect 来处理工具属性的更新
  useEffect(() => {
    if (context) {
      context.strokeStyle = tool === 'eraser' ? '#ffffff' : color;
      context.lineWidth = lineWidth;
    }
  }, [color, lineWidth, tool, context]);

  const startDrawing = (e: React.MouseEvent<HTMLCanvasElement>) => {
    if (!context || !canvasRef.current) return;
    
    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();

    // Save current canvas state for undo
    const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
    setUndoStack([...undoStack, imageData]);

    setIsDrawing(true);
    
    context.beginPath();
    context.moveTo(
      e.clientX - rect.left,
      e.clientY - rect.top
    );
  };

  const draw = (e: React.MouseEvent<HTMLCanvasElement>) => {
    if (!isDrawing || !context) return;
    
    const canvas = canvasRef.current;
    if (!canvas) return;

    const rect = canvas.getBoundingClientRect();
    // 根据当前工具设置样式
    context.strokeStyle = tool === 'eraser' ? '#ffffff' : color;
    context.lineWidth = lineWidth;
    
    context.lineTo(
      e.clientX - rect.left,
      e.clientY - rect.top
    );
    context.stroke();
  };

  const stopDrawing = () => {
    if (!context) return;
    setIsDrawing(false);
    context.closePath();
  };

  const handleUndo = () => {
    if (!context || !canvasRef.current || undoStack.length === 0) return;
    
    const previousState = undoStack[undoStack.length - 1];
    context.putImageData(previousState, 0, 0);
    setUndoStack(undoStack.slice(0, -1));
  };

  const handleClear = () => {
    if (!context || !canvasRef.current) return;
    
    // 保存当前状态用于撤销
    const imageData = context.getImageData(0, 0, canvasRef.current.width, canvasRef.current.height);
    setUndoStack([...undoStack, imageData]);
    
    // 清除画布
    context.fillStyle = '#ffffff';
    context.fillRect(0, 0, canvasRef.current.width, canvasRef.current.height);
  };

  const handleSave = () => {
    if (!canvasRef.current) return;
    
    const link = document.createElement('a');
    link.download = 'drawing.png';
    link.href = canvasRef.current.toDataURL();
    link.click();
  };

  // 修改拖动相关的处理函数
  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    // 检查是否点击的是工具栏区域
    if (e.target instanceof Element && e.target.closest(`.${styles['canvas-toolbar']}`)) {
      setIsDragging(true);
      setDragStart({
        x: e.clientX - position.x,
        y: e.clientY - position.y
      });
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  // 添加全局鼠标事件监听
  useEffect(() => {
    const handleGlobalMouseUp = () => {
      setIsDragging(false);
    };

    const handleGlobalMouseMove = (e: MouseEvent) => {
      if (isDragging) {
        const newX = e.clientX - dragStart.x;
        const newY = e.clientY - dragStart.y;
        setPosition({
          x: newX,
          y: newY
        });
      }
    };

    if (isOpen) {
      window.addEventListener('mouseup', handleGlobalMouseUp);
      window.addEventListener('mousemove', handleGlobalMouseMove);
    }

    return () => {
      window.removeEventListener('mouseup', handleGlobalMouseUp);
      window.removeEventListener('mousemove', handleGlobalMouseMove);
    };
  }, [isOpen, isDragging, dragStart]);

  if (!isOpen) return null;

  return (
    <div 
      className={styles['canvas-container']}
      style={{
        transform: `translate(calc(-50% + ${position.x}px), ${position.y}px)`,
      }}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
    >
      <div className={styles['canvas-toolbar']}>
        <button 
          className={`${styles['canvas-tool-btn']} ${tool === 'pen' ? styles.active : ''}`}
          onClick={() => setTool('pen')}
        >
          <FaPen /> {t('pen')}
        </button>
        <button 
          className={`${styles['canvas-tool-btn']} ${tool === 'eraser' ? styles.active : ''}`}
          onClick={() => setTool('eraser')}
        >
          <FaEraser /> {t('eraser')}
        </button>
        <input
          type="color"
          className={styles['color-picker']}
          value={color}
          onChange={(e) => setColor(e.target.value)}
        />
        <input
          type="range"
          min="1"
          max="10"
          value={lineWidth}
          onChange={(e) => setLineWidth(Number(e.target.value))}
        />
        <button className={styles['canvas-tool-btn']} onClick={handleUndo}>
          <FaUndo /> {t('undo')}
        </button>
        <button className={styles['canvas-tool-btn']} onClick={handleClear}>
          <FaTrash /> {t('clear')}
        </button>
        <button className={styles['canvas-tool-btn']} onClick={handleSave}>
          <FaSave /> {t('save')}
        </button>
        <button className={styles['canvas-tool-btn']} onClick={onClose}>
          <FaTimes /> {t('close')}
        </button>
      </div>
      <div className={styles['canvas-area']}>
        <canvas
          ref={canvasRef}
          width={800}
          height={600}
          onMouseDown={startDrawing}
          onMouseMove={draw}
          onMouseUp={stopDrawing}
          onMouseLeave={stopDrawing}
        />
      </div>
    </div>
  );
};

export default DrawingCanvas; 