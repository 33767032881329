import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './Navbar.css';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { logout } from '../store/slices/userSlice';
import { setConfirmDialog } from '../store/slices/uiSlice';
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from './LanguageSwitcher';
import { UserType } from '../types/user';

const Navbar = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { userType, firstName, lastName } = useAppSelector(state => state.user);
  const navigate = useNavigate();
  const { avatar } = useAppSelector(state => state.user);
  const location = useLocation();
  
  const handleLogout = () => {
    if (location.pathname.startsWith('/room/')) {
      dispatch(setConfirmDialog({
        isOpen: true,
        title: (t as any)('confirm'),
        message: (t as any)('leaveRoomConfirm'),
        onConfirm: () => {
          dispatch(logout());
          navigate('/login');
        }
      }));
      return;
    }
    dispatch(logout());
    navigate('/login');
  };

  const toHome = () => {
    if (location.pathname.startsWith('/room/')) {
      dispatch(setConfirmDialog({
        isOpen: true,
        title: (t as any)('confirm'),
        message: (t as any)('leaveRoomConfirm'),
        onConfirm: () => {
          navigateToHome();
        }
      }));
      return;
    }
    navigateToHome();
  };

  const navigateToHome = () => {
    if (UserType.ADMIN === userType) {
      navigate('/admin');
    }   
    if (UserType.TEACHER === userType) {
      navigate('/teacher');
    }
    if (UserType.STUDENT === userType) {
      navigate('/');
    }
  };

  return (
    <nav className="navbar">
      <div className="navbar-brand">
        <span 
          className="brand-text"
          onClick={() => toHome()}
          style={{ cursor: 'pointer' }}
        >
          WULIMALI
        </span>
      </div>
      <div className="navbar-menu">
        <div 
          className="user-profile"
          onClick={() => navigate('/profile')}
        >
          <img 
            src={avatar || 'https://api.dicebear.com/7.x/thumbs/svg?seed=6&radius=50'} 
            alt="用户头像" 
            className="navbar-avatar"
          />
          <span className="username">
            {firstName} {lastName}
          </span>
        </div>
        <div className="nav-buttons">
          <LanguageSwitcher />
          {!location.pathname.startsWith('/room/') && (
            <button 
              className="nav-link"
              onClick={handleLogout}
          >
            {(t as any)('logout')}
            </button>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Navbar; 