export enum UserType {
  ADMIN = 'UT01',
  TEACHER = 'UT02',
  STUDENT = 'UT03'
}

// 用户类型标签映射
export const UserTypeLabel: Record<UserType, string> = {
    [UserType.ADMIN]: 'admin',
    [UserType.TEACHER]: 'teacher',
    [UserType.STUDENT]: 'student'
};

// 类型保护函数
export function isValidUserType(type: string | null): type is UserType {
    return type !== null && Object.values(UserType).includes(type as UserType);
}

export interface User {
  userId: string | null;
  email: string | null;
  username: string | null;
  lastName: string | null;
  firstName: string | null;
  avatar: string | null;
  userType: string | null;
}

export interface UpdateUserPayload {
  username?: string;
  lastName?: string;
  firstName?: string;
  email?: string;
  briefIntro?: string;
}

// 用于状态管理的扩展接口
export interface UserState {
  token: string | null;
  userId: string | null;
  userType: string | null;
  lastName: string | null;
  firstName: string | null;
  avatar: string | null;
  isAuthenticated: boolean;
} 