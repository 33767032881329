import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FaDownload, FaFile, FaShareSquare } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { create } from 'zustand';
import { downloadFile } from '../services/downloadService';
import { useAppSelector } from '../store/hooks';
import { sendChatMessage, sendFileMessage } from '../store/slices/websocketSlice';
import api from '../utils/ApiClient';
import { formatFileSize } from '../utils/formatters';
import './ChatWindow.css';
import Toast from './Toast';


// 创建一个全局状态存储
interface ChatMassageStore {
    messages: any[];
    addMessage: (message: any) => void;
    clearMessages: () => void;
}

export const useChatMassageStore = create<ChatMassageStore>((set) => ({
    messages: [],
    addMessage: (message) => set((state) => ({
        messages: [...state.messages, message]
    })),
    clearMessages: () => set({ messages: [] })
}));

interface ChatWindowProps {
    isExpanded: boolean;
    onClose: () => void;
    t: (key: string) => string;
}

const ChatWindow: React.FC<ChatWindowProps> = ({
    isExpanded,
    onClose,
    t
}) => {
    const [inputMessage, setInputMessage] = useState('');
    // const [messages, setMessages] = useState<any[]>([]);
    const messagesEndRef = useRef<HTMLDivElement>(null);
    const dispatch = useDispatch();
    const { userId, firstName, lastName } = useAppSelector(state => state.user);
    const { roomId } = useParams();
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const { messages, addMessage, clearMessages } = useChatMassageStore();
    const [downloadProgress, setDownloadProgress] = useState<Record<string, number>>({});
    const progressTimeouts = useRef<Record<string, NodeJS.Timeout>>({});

    const showToastMessage = useCallback((message: string, duration = 3000) => {
        setToastMessage(message);
        setShowToast(true);

        // 自动关闭
        setTimeout(() => {
            setShowToast(false);
        }, duration);
    }, []);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    // 组件卸载时清理超时器
    useEffect(() => {
        return () => {
            // 组件卸载时清除消息
            clearMessages();
            // 清理超时器
            Object.values(progressTimeouts.current).forEach(timeout => {
                clearTimeout(timeout);
            });
        };
    }, []);

    const handleSendMessage = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!inputMessage.trim()) return;

        console.log(roomId);

        dispatch(sendChatMessage({
            type: 'chat',
            roomId: roomId || '',
            userId: userId || '',
            firstName: firstName || '',
            lastName: lastName || '',
            text: inputMessage
        }));

        addMessage({
            text: inputMessage,
            self: true,
            timestamp: new Date().toISOString()
        });

        setInputMessage('');
    };

    const handleFileSelect = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (!file) return;
        try {
            // 添加文件大小检查
            console.log(file.size);
            const MAX_FILE_SIZE = 150 * 1024 * 1024; // 150MB
            if (file.size > MAX_FILE_SIZE) {
                showToastMessage('文件大小不能超过150MB');
                return;
            }

            const formData = new FormData();
            formData.append('file', file);
            formData.append('roomId', roomId || '');
            formData.append('userId', userId || '');

            // 上传文件
            const response = await api.post('/room/file/upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            
            // 发送WebSocket消息
            dispatch(sendFileMessage({
                type: 'file',
                roomId: roomId || '',
                userId: userId || '',
                firstName: firstName || '',
                lastName: lastName || '',
                fileName: file.name,
                fileUrl: '',
                fileSize: file.size,
                fileType: file.type
            }));

            // 添加到本地消息列表
            addMessage({
                type: 'file',
                self: true,
                fileName: file.name,
                fileUrl: '',
                fileSize: file.size,
                fileType: file.type,
                timestamp: new Date().toISOString()
            });

        } catch (error) {
            console.error('文件上传失败:', error);
            showToastMessage('文件上传失败');
        }
    };

    const handleDownload = async (fileName: string) => {
        try {
            if (!roomId) {
                showToastMessage('roomID is null');
                return;
            }
            
            // 清除之前的超时器
            if (progressTimeouts.current[fileName]) {
                clearTimeout(progressTimeouts.current[fileName]);
            }
            
            // 初始化进度为0
            setDownloadProgress(prev => ({ ...prev, [fileName]: 0 }));
            
            const handle = await window.showSaveFilePicker({
                suggestedName: fileName,
            });

            await downloadFile(roomId, fileName, handle, (progress) => {
                console.log(`${fileName} progress:`, progress);
                // 使用函数式更新确保进度只会增加不会减少
                setDownloadProgress(prev => ({
                    ...prev,
                    [fileName]: Math.max(prev[fileName] || 0, progress)
                }));
            });

            // 下载完成后延迟清除进度显示
            progressTimeouts.current[fileName] = setTimeout(() => {
                setDownloadProgress(prev => {
                    const newProgress = { ...prev };
                    delete newProgress[fileName];
                    return newProgress;
                });
                delete progressTimeouts.current[fileName];
            }, 1000);

        } catch (error) {       
            console.error("download error:", error);
            // 立即清除进度
            setDownloadProgress(prev => {
                const newProgress = { ...prev };
                delete newProgress[fileName];
                return newProgress;
            });
        }
    };

    return (
        <div className={`room-chat ${!isExpanded ? 'room-chat--collapsed' : ''}`}>
            <button 
                className="mobile-close-chat"
                onClick={onClose}
            >
                X
            </button>
            <div className="room-chat-messages">
                {messages.map((msg, index) => (
                    <div 
                        key={index} 
                        className={`room-chat-message ${msg.self ? 'room-chat-message--self' : 'room-chat-message--other'}`}
                    >
                        <div className="room-chat-message-header">
                            {!msg.self && <span className="room-chat-user-name">{msg.firstName} {msg.lastName}</span>}
                            <span className="room-chat-timestamp">
                                {new Date(msg.timestamp).toLocaleTimeString()}
                            </span>
                        </div>
                        <div className="room-chat-message-text">
                            {msg.type === 'file' ? (
                                <div className="file-message">
                                    <FaFile className="file-message-icon" />
                                    <div className="file-message-info">
                                        <div className="file-message-name">{msg.fileName}</div>
                                        <div className="file-message-size">{formatFileSize(msg.fileSize || 0)}</div>
                                        {downloadProgress[msg.fileName] !== undefined && (
                                            <div className="download-progress">
                                                <div 
                                                    className="progress-bar"
                                                    style={{ width: `${downloadProgress[msg.fileName]}%` }}
                                                />
                                                <span className="progress-text">
                                                    {downloadProgress[msg.fileName]}%
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                    <button 
                                        onClick={() => handleDownload(msg.fileName)}
                                        className="file-download-btn"
                                        title="下载文件"
                                        disabled={downloadProgress[msg.fileName] !== undefined}
                                    >
                                        <FaDownload />
                                    </button>
                                </div>
                            ) : (
                                msg.text
                            )}
                        </div>
                    </div>
                ))}
                <div ref={messagesEndRef} />
            </div>
            <form className="room-chat-message-input" onSubmit={handleSendMessage}>
                <div className="chat-input-container">
                    <input
                        type="text"
                        value={inputMessage}
                        onChange={(e) => setInputMessage(e.target.value)}
                        placeholder="输入消息..."
                    />
                    <label className="file-upload-label" title="发送文件">
                        <input
                            type="file"
                            onChange={handleFileSelect}
                            style={{ display: 'none' }}
                        />
                        <FaShareSquare className="file-icon" />
                    </label>
                </div>
                <button type="submit">{t('sendBtn')}</button>
            </form>
            {showToast && (
                <Toast
                    message={toastMessage}
                    onClose={() => setShowToast(false)}
                />
            )}
        </div>
    );
};

export default ChatWindow; 