import { useTranslation } from 'react-i18next';
import './LanguageSwitcher.css';

const LanguageSwitcher = () => {
    const { i18n } = useTranslation();

    const changeLanguage = () => {
        const nextLang = i18n.language === 'en' ? 'zh' : 'en';
        i18n.changeLanguage(nextLang);
    };

    return (
        <div className="language-switcher">
            <button 
                className="language-button"
                onClick={changeLanguage}
            >
                🌐 {i18n.language.toUpperCase()}
            </button>
        </div>
    );
};

export default LanguageSwitcher;