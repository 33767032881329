import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import LanguageSwitcher from '../components/LanguageSwitcher';
import ValidateCaptcha from '../components/ValidateCaptcha';
import { setUser } from '../store/slices/userSlice';
import api from '../utils/ApiClient';
import './SellerLogin.css';

function SellerLogin() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [showSlider, setShowSlider] = useState(false);
    const dispatch = useDispatch();

    // 验证输入信息并显示滑块
    const handleLogin = () => {
        if (!username || !password) {
            alert(t('fillAllFields'));
            return;
        }
        setShowSlider(true);
    };

    // 验证成功后调用登录接口
    const handleLoginSubmit = async () => {
        try {
            const response = await api.post('/sellers/login', {
                username,
                password,
            });

            if (response.status === 200) {
                const { token, id, lastName, firstName, avatar } = response.data;
                dispatch(setUser({
                    token,
                    userId: id,
                    userType: "",
                    lastName,
                    firstName,
                    avatar,
                }));
                navigate('/seller');
            } else {
                alert(response.data.message || t('loginFailed'));
            }
        } catch (error: any) {
            alert(error.response.data || t('loginFailed'));
            setShowSlider(false); // 登录失败时关闭验证框
        }
    };

    return (
        <div className="seller-login-page">
            <div className="seller-login-form">
                <div className="seller-login-header">
                    <h1 className="seller-login-title">{(t as any)('login')}</h1>
                    <LanguageSwitcher />
                </div>
                <div className="seller-login-content">
                    <input
                        className="seller-login-input"
                        type="text"
                        placeholder={(t as any)('username')}
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        autoCapitalize="none"
                        autoComplete="off"
                        readOnly={showSlider}
                    />

                    <input
                        className="seller-login-input"
                        type="password"
                        placeholder={(t as any)('password')}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        autoComplete="off"
                        readOnly={showSlider}
                    />

                    <button
                        className="seller-login-submit-btn"
                        onClick={handleLogin}
                    >
                        {(t as any)('loginButton')}
                    </button>

                    <button
                        className="seller-login-register-link"
                        onClick={() => navigate('/register')}
                    >
                        {(t as any)('registerNow')}
                    </button>
                </div>

                {showSlider && (
                    <ValidateCaptcha
                        onSuccess={handleLoginSubmit}
                        onClose={() => setShowSlider(false)}
                    />
                )}
            </div>
        </div>
    );
}

export default SellerLogin;
