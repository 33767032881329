// 支付成功页面
import React from 'react';
import './PaySuccess.css';
import { useNavigate } from 'react-router-dom';

const PaySuccess: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div className="pay-success-container">      
        <div className="pay-success-icon"></div>
        <div className="pay-success-text">
            <h2>支付成功</h2>
            <p>您的订单已成功支付，感谢您的支持！</p>
            <div className="pay-success-button">
                <button onClick={() => navigate('/')}>返回首页</button>
                <button onClick={() => navigate('/rechargeList')}>查看充值信息</button>
            </div>
        </div>
    </div>
  );
};

export default PaySuccess;