import React, { useEffect } from 'react';
import './Toast.css';

interface ToastProps {
    message: string;
    duration?: number;
    onClose: () => void;
}

const Toast: React.FC<ToastProps> = ({ message, duration = 5000, onClose }) => {
    useEffect(() => {
        console.log('Toast mounted:', {
            message,
            duration,
            timestamp: new Date().toISOString()
        });

        const timer = setTimeout(() => {
            console.log('Toast timeout triggered');
            onClose();
        }, duration);

        return () => {
            console.log('Toast unmounted');
            clearTimeout(timer);
        };
    }, [duration, onClose, message]);

    return (
        <div className="toast-overlay">
            <div className="toast-content">
                {message}
            </div>
        </div>
    );
};

export default Toast; 