import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaBook, FaComments, FaCommentSlash, FaDesktop, FaMicrophone, FaMicrophoneSlash, FaPen, FaSignOutAlt, FaVideo, FaVideoSlash, FaExpandAlt, FaCompressAlt } from 'react-icons/fa';
import { useNavigate, useParams } from 'react-router-dom';
import BookManagement from '../components/BookManagement';
import ChatWindow from '../components/ChatWindow';
import DrawingCanvas from '../components/DrawingCanvas';
import Toast from '../components/Toast';
import { useSocketStore } from '../components/WebSocketMessageHandler';
import { useGoToHome } from '../hook/useCommonFunction';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { setConfirmDialog } from '../store/slices/uiSlice';
import {
    cleanup,
    setAudioEnabled,
    setVideoEnabled
} from '../store/slices/webrtcSlice';
import { disconnect } from '../store/slices/websocketSlice';
import { UserType } from '../types/user';
import api from '../utils/ApiClient';
import './Room.css';

// 添加扩展的接口定义
interface DisplayMediaSettings extends MediaTrackSettings {
    displaySurface?: 'monitor' | 'window' | 'browser' | 'application';
}

const Room = () => {
    const { t } = useTranslation();
    const { roomId } = useParams();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { firstName, lastName, userType } = useAppSelector(state => state.user);
    const goToHome = useGoToHome();
    const [isBookManagementOpen, setIsBookManagementOpen] = useState(false);

    // UI states
    const [isChatExpanded, setIsChatExpanded] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [isRemoteMain, setIsRemoteMain] = useState(false);
    const [isScreenSharing, setIsScreenSharing] = useState(false);
    const [sharingStream, setSharingStream] = useState<MediaStream | null>(null);
    const [isDrawingMode, setIsDrawingMode] = useState(false);
    const [isWideMode, setIsWideMode] = useState(false);
    
    // Refs
    const mainVideoRef = useRef<HTMLVideoElement>(null);
    const subVideoRef = useRef<HTMLVideoElement>(null);

    // Redux states
    const { userId } = useAppSelector(state => state.user);
    const { status: wsStatus, 
        messages: wsMessages } = useAppSelector(state => state.websocket);
    const { status: webrtcStatus, 
        localStream, 
        remoteStream, 
        isVideoEnabled, 
        isAudioEnabled,
        peerConnection } = useAppSelector(state => state.webrtc);
    
    const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

    // 在组件顶部添加状态监听
    useEffect(() => {
        console.log('Toast state changed:', {
            showToast,
            toastMessage,
            timestamp: new Date().toISOString()
        });
    }, [showToast, toastMessage]);

    const showToastMessage = useCallback((message: string, duration = 5000) => {
        setToastMessage(message);
        setShowToast(true);

        // 自动关闭
        setTimeout(() => {
            setShowToast(false);
        }, duration);
    }, []);

    useEffect(() => {
        // 当离开房间时，清理 WebRTC 和 WebSocket 连接
        return () => {
            dispatch(disconnect());
            dispatch(cleanup());
            if (UserType.TEACHER === userType) {
                api.post('/teachers/teacherDown', {
                    userId,
                    roomId
                }).then(response => console.log('Room closed:', response));
            }
        };
    }, []);

    useEffect(() => {
        if (UserType.TEACHER === userType) {
            if (wsStatus === 'connected' && webrtcStatus === 'connected') {
                api.post('/teachers/teacherUp', {
                    userId,
                    roomId
                }).then(response => console.log('Room created:', response));
            }
            if (wsStatus !== 'connected' || webrtcStatus !== 'connected') {
                api.post('/teachers/teacherDown', {
                    userId,
                    roomId
                }).then(response => console.log('Room closed:', response));
                dispatch(setConfirmDialog({
                    isOpen: true,
                    title: (t as any)('confirm'),
                    message: (t as any)('connectionLost'),
                    onConfirm: () => {
                        goToHome();
                    }
                }));
            }
        }
    }, [wsStatus, webrtcStatus]);

    // Toggle video/audio
    const toggleVideo = () => {
        if (localStream) {
            localStream.getVideoTracks().forEach(track => {
                track.enabled = !track.enabled;
            });
            dispatch(setVideoEnabled(!isVideoEnabled));
        }
    };

    const toggleAudio = () => {
        if (localStream) {
            localStream.getAudioTracks().forEach(track => {
                track.enabled = !track.enabled;
            });
            dispatch(setAudioEnabled(!isAudioEnabled));
        }
    };

    // Handle force leave
    useEffect(() => {
        const handleForceLeave = (message: string) => {
            showToastMessage(message);
            delay(3000).then(() => {
                navigate('/');
            });
        };
        useSocketStore.getState().subscribe('forceLeave', handleForceLeave);
        return () => {
            useSocketStore.getState().unsubscribe('forceLeave', handleForceLeave);
        };
    }, []);

    // Handle user leave room
    // const handleUserLeaveRoom = (data: any) => {
    //     setMessages(prev => [...prev, {
    //         text: data.payload.message,
    //         self: false,
    //         firstName: data.payload.firstName,
    //         lastName: data.payload.lastName,
    //         timestamp: data.timestamp
    //     }]);
    //     dispatch(cleanupRemoteStream({
    //         roomId: roomId || '',
    //         userId: userId || ''
    //     }));
    // };

    // 初始化视频流
    useEffect(() => {
        initStream();
    }, []);

    const initStream = () => {
        if (localStream && mainVideoRef.current) {
            mainVideoRef.current.srcObject = localStream;
            mainVideoRef.current.muted = true; //静音
        }
        if (remoteStream && subVideoRef.current) {
            subVideoRef.current.srcObject = remoteStream;
            subVideoRef.current.muted = false; //播放
        }
    }

    useEffect(() => {
        if (UserType.TEACHER === userType) {
            if (remoteStream) {
                setRemoteStream();
            }
        }
    }, [remoteStream]);

    // Handle join room
    useEffect(() => {
        const handleJoinRoom = () => {
            if (remoteStream) {
                setRemoteStream();
            }
        };
        useSocketStore.getState().subscribe('joinRoom', handleJoinRoom);
        return () => {
            useSocketStore.getState().unsubscribe('joinRoom', handleJoinRoom);
        };
    }, []);

    const setRemoteStream = () => {
        if (remoteStream && subVideoRef.current) {
            const audioTrack = remoteStream.getAudioTracks()[0];
            const videoTrack = remoteStream.getVideoTracks()[0];

            console.log('[DEBUG] Remote tracks after delay:', {
                audioTrack: audioTrack ? {
                    enabled: audioTrack.enabled,
                    muted: audioTrack.muted,
                    readyState: audioTrack.readyState
                } : 'No audio track',
                videoTrack: videoTrack ? {
                    enabled: videoTrack.enabled,
                    muted: videoTrack.muted,
                    readyState: videoTrack.readyState
                } : 'No video track'
            });
            console.log('[DEBUG] subVideoRef 触发了:', remoteStream);
            subVideoRef.current.srcObject = remoteStream;
        }
    }

    // 添加视频交换处理函数
    const handleVideoSwap = () => {
        let isRemoteMainTemp = !isRemoteMain;
        // 视频交换
        videoSwap();
        setIsRemoteMain(isRemoteMainTemp);
    };

    const videoSwap = () => {
        // 交换流
        if (mainVideoRef.current && subVideoRef.current) {
            let tempVideo = mainVideoRef.current.srcObject;
            mainVideoRef.current.srcObject = subVideoRef.current.srcObject;
            subVideoRef.current.srcObject = tempVideo;
            mainVideoRef.current.muted = !mainVideoRef.current.muted;
            subVideoRef.current.muted = !subVideoRef.current.muted;
        }
        // const mainVideo = document.querySelector('.room-main-video');
        // if (window.getComputedStyle(mainVideo as Element).objectFit === 'cover') {
        //     document.querySelector('.room-main-video-container')?.classList.add('object-fit-contain');
        // } else {
        //     document.querySelector('.room-main-video-container')?.classList.remove('object-fit-contain');
        // }

        // 交换主屏的contain
        const mainVideoContainer = document.querySelector('.room-main-video-container');
        if (mainVideoContainer?.classList.contains('object-fit-contain')) {
            document.querySelector('.room-main-video-container')?.classList.remove('object-fit-contain');
        } else {
            document.querySelector('.room-main-video-container')?.classList.add('object-fit-contain');
        }
    }

    // 添加一个 useEffect 来处理视频切换后的静音状态
    // useEffect(() => {
    //     if (mainVideoRef.current && subVideoRef.current) {
    //         if (isRemoteMain) {
    //             let tempVideo = localStream;
    //             mainVideoRef.current.srcObject = remoteStream;
    //             subVideoRef.current.srcObject = localStream;
    //             mainVideoRef.current.muted = false; //播放
    //             subVideoRef.current.muted = true; //静音
    //             // 添加屏幕主屏的contain
    //             document.querySelector('.room-main-video-container')?.classList.add('object-fit-contain');
    //         } else {
    //             mainVideoRef.current.srcObject = localStream;
    //             subVideoRef.current.srcObject = remoteStream;
    //             mainVideoRef.current.muted = true; //静音
    //             subVideoRef.current.muted = false; //播放
    //             // 移除屏幕主屏的contain
    //             document.querySelector('.room-main-video-container')?.classList.remove('object-fit-contain');
    //         }
    //     }
    // }, [isRemoteMain]);  // 当 isRemoteMain 变化时执行

    // 点击页面处理
    const handleClick = (e: React.MouseEvent) => {
        // 检查点击是否在聊天窗口之外
        const chatElement = document.querySelector('.room-chat');
        const controlsElement = document.querySelector('.room-controls');
        
        if (chatElement && controlsElement) {
            const isClickInChat = chatElement.contains(e.target as Node);
            const isClickInControls = controlsElement.contains(e.target as Node);
            
            // 如果点击不在聊天窗口和控制栏内，且聊天窗口是展开的，则收起聊天窗口
            if (!isClickInChat && !isClickInControls && isChatExpanded) {
                setIsChatExpanded(false);
            }
        }
    };

    const handleExit = () => {
        dispatch(setConfirmDialog({
            isOpen: true,
            title: (t as any)('confirm'),
            message: (t as any)('leaveRoomConfirm'),
            onConfirm: () => {
                goToHome();
            }
        }));
        return;
    };

    const toggleScreenShare = async () => {
        // 检查是否支持屏幕共享
        if (!navigator.mediaDevices || !navigator.mediaDevices.getDisplayMedia) {
            showToastMessage((t as any)('screenShareNotSupported'));
            return;
        }

        try {
            if (!isScreenSharing) {
                const stream = await navigator.mediaDevices.getDisplayMedia({
                    video: true,
                    audio: true
                });
                
                // 检查是否共享整个屏幕
                const videoTrack = stream.getVideoTracks()[0];
                const settings = videoTrack.getSettings() as DisplayMediaSettings;
                const isFullScreen = settings.displaySurface === 'monitor';

                console.log('[DEBUG] isFullScreen:', isFullScreen);
                if (isFullScreen && mainVideoRef.current) {
                    // 如果是共享整个屏幕，将主视频设置为黑屏并隐藏视频内容
                    mainVideoRef.current.style.backgroundColor = '#000';
                    mainVideoRef.current.style.opacity = '0';
                    
                    // 添加屏幕共享提示文本
                    const container = mainVideoRef.current.parentElement;
                    if (container) {
                        const sharingText = document.createElement('div');
                        sharingText.className = 'screen-sharing-text';
                        sharingText.textContent = (t as any)('screenSharingInProgress');
                        container.appendChild(sharingText);
                    }
                }
                
                setSharingStream(stream);
                setIsScreenSharing(true);
                
                // 添加屏幕主屏的contain
                document.querySelector('.room-main-video-container')?.classList.add('object-fit-contain');

                // Replace the video track in the local stream
                const sender = peerConnection?.getSenders().find(s => s.track?.kind === 'video');
                if (sender) {
                    sender.replaceTrack(videoTrack);
                }

                // Handle when user stops sharing via browser controls
                videoTrack.onended = () => {
                    stopScreenSharing();
                };
                // 初始化流
                initStream();
                // 设置分享流
                if (mainVideoRef.current) {
                    mainVideoRef.current.srcObject = stream;
                }
            } else {
                stopScreenSharing();
            }
        } catch (err) {
            // 用户取消分享时不显示错误消息
            if (err instanceof Error && err.name === 'NotAllowedError') {
                return;
            }
            // 其他错误才显示错误消息
            console.error('Error sharing screen:', err);
            showToastMessage((t as any)('screenShareError'));
        }
    };

    const stopScreenSharing = () => {
        if (sharingStream) {
            sharingStream.getTracks().forEach(track => track.stop());
        }
        setSharingStream(null);
        setIsScreenSharing(false);
        
        // 移除屏幕主屏的contain
        document.querySelector('.room-main-video-container')?.classList.remove('object-fit-contain');
        
        // 恢复主视频背景色和可见性，并移除提示文本
        if (mainVideoRef.current) {
            mainVideoRef.current.style.backgroundColor = 'transparent';
            mainVideoRef.current.style.opacity = '1';
            
            // 移除提示文本
            const container = mainVideoRef.current.parentElement;
            const sharingText = container?.querySelector('.screen-sharing-text');
            if (sharingText) {
                sharingText.remove();
            }
        }
        
        // Restore camera video track
        if (localStream) {
            const videoTrack = localStream.getVideoTracks()[0];
            const sender = peerConnection?.getSenders().find(s => s.track?.kind === 'video');
            // 添加检查确保 peerConnection 仍然是连接状态
            if (sender && videoTrack && peerConnection?.connectionState !== 'closed') {
                try {
                    sender.replaceTrack(videoTrack)
                        .catch(err => {
                            console.error('Failed to replace track:', err);
                        });
                } catch (err) {
                    console.error('Error replacing track:', err);
                }
            } else {
                console.warn('PeerConnection is closed or unavailable, cannot replace track');
            }
            // 初始化流
            initStream();
        }
    };

    const handleBookManagement = () => {
        setIsBookManagementOpen(true);
    };

    // 添加拖动相关的状态
    const [isDragging, setIsDragging] = useState(false);
    const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 });
    const pipRef = useRef<HTMLDivElement>(null);

    // 添加一个状态来追踪是否发生了拖动
    const [hasDragged, setHasDragged] = useState(false);

    // 处理拖动开始
    const handleDragStart = (e: React.MouseEvent | React.TouchEvent) => {
        if (!pipRef.current) return;

        let clientX: number, clientY: number;
        
        if ('touches' in e) {
            clientX = e.touches[0].clientX;
            clientY = e.touches[0].clientY;
        } else {
            clientX = e.clientX;
            clientY = e.clientY;
        }

        const rect = pipRef.current.getBoundingClientRect();
        setDragOffset({
            x: clientX - rect.left,
            y: clientY - rect.top
        });
        setIsDragging(true);
        setHasDragged(false); // 重置拖动状态
        pipRef.current.classList.add('dragging');
    };

    // 处理拖动
    const handleDrag = useCallback((e: MouseEvent | TouchEvent) => {
        if (!isDragging || !pipRef.current) return;

        setHasDragged(true); // 标记发生了拖动

        let clientX: number, clientY: number;
        
        if ('touches' in e) {
            clientX = e.touches[0].clientX;
            clientY = e.touches[0].clientY;
        } else {
            clientX = e.clientX;
            clientY = e.clientY;
        }

        // 获取视口尺寸
        const viewportWidth = window.innerWidth;
        const viewportHeight = window.innerHeight;
        const pipRect = pipRef.current.getBoundingClientRect();
        
        // 导航栏高度（从 CSS 中获取）
        const navbarHeight = 64; // 包含 padding 的导航栏总高度

        // 计算新位置，允许部分超出视口
        let newX = clientX - dragOffset.x;
        let newY = clientY - dragOffset.y;

        // 保证至少有 20px 的部分在视口内，并且不超过导航栏
        const minVisible = 20;
        newX = Math.max(-pipRect.width + minVisible, Math.min(newX, viewportWidth - minVisible));
        newY = Math.max(navbarHeight, Math.min(newY, viewportHeight - minVisible)); // 修改这里，设置最小值为导航栏高度

        pipRef.current.style.right = 'auto';
        pipRef.current.style.top = `${newY}px`;
        pipRef.current.style.left = `${newX}px`;
    }, [isDragging, dragOffset]);

    // 处理拖动结束
    const handleDragEnd = useCallback(() => {
        if (!pipRef.current) return;
        
        setIsDragging(false);
        pipRef.current.classList.remove('dragging');
    }, []);

    // 添加和移除事件监听器
    useEffect(() => {
        if (isDragging) {
            window.addEventListener('mousemove', handleDrag);
            window.addEventListener('touchmove', handleDrag);
            window.addEventListener('mouseup', handleDragEnd);
            window.addEventListener('touchend', handleDragEnd);
        }

        return () => {
            window.removeEventListener('mousemove', handleDrag);
            window.removeEventListener('touchmove', handleDrag);
            window.removeEventListener('mouseup', handleDragEnd);
            window.removeEventListener('touchend', handleDragEnd);
        };
    }, [isDragging, handleDrag, handleDragEnd]);

    // 修改点击处理函数
    const handlePipClick = (e: React.MouseEvent) => {
        // 只有在没有发生拖动的情况下才执行视频交换
        if (!hasDragged) {
            handleVideoSwap();
        }
        // 重置拖动状态
        setHasDragged(false);
    };

    const toggleWideMode = () => {
        setIsWideMode(!isWideMode);
        const videoSection = document.querySelector('.room-video-section');
        if (videoSection) {
            videoSection.classList.toggle('wide-mode');
        }
    };

    // Render component
    return (
        <div className="room" onClick={handleClick}>
            <div className="room-video-section">
                <div className="room-main-video-container">
                    <video
                        ref={mainVideoRef}
                        autoPlay
                        playsInline
                        className="room-main-video"
                        onLoadedMetadata={(e) => {
                            console.log('[DEBUG] Main video loadedmetadata:', {
                                videoWidth: e.currentTarget.videoWidth,
                                videoHeight: e.currentTarget.videoHeight,
                                readyState: e.currentTarget.readyState,
                                paused: e.currentTarget.paused,
                                currentSrc: e.currentTarget.currentSrc,
                                srcObject: e.currentTarget.srcObject
                            });

                            const videoTrack = (mainVideoRef.current?.srcObject as MediaStream)
                                ?.getTracks()
                                .find(track => track.kind === 'video');

                            const audioTrack = (mainVideoRef.current?.srcObject as MediaStream)
                                ?.getTracks()
                                .find(track => track.kind === 'audio');

                            console.log('[DEBUG] Main video track details:', {
                                enabled: videoTrack?.enabled,
                                muted: videoTrack?.muted,
                                readyState: videoTrack?.readyState,
                                id: videoTrack?.id,
                                label: videoTrack?.label,
                                settings: videoTrack?.getSettings()
                            });

                            console.log('[DEBUG] Main audio track details:', {
                                enabled: audioTrack?.enabled,
                                muted: audioTrack?.muted,
                                readyState: audioTrack?.readyState,
                                id: audioTrack?.id,
                                label: audioTrack?.label,
                                settings: audioTrack?.getSettings()
                            });

                            // Force play attempt
                            e.currentTarget.play().catch(err => {
                                console.error('[DEBUG] Auto-play failed:', err);
                            });
                        }}
                        onPlay={() => {
                            console.log('[DEBUG] Main video started playing');
                            // 检查视频轨道状态
                            const track = (mainVideoRef.current?.srcObject as MediaStream)
                                ?.getTracks()
                                .find(track => track.kind === 'audio');
                            console.log('[DEBUG] Main audio track status:', {
                                enabled: track?.enabled,
                                muted: track?.muted,
                                readyState: track?.readyState
                            });
                        }}
                    />
                </div>
                <div className="room-pip-video-container" 
                    ref={pipRef}
                    onClick={handlePipClick}
                    onMouseDown={handleDragStart}
                    onTouchStart={handleDragStart}
                >
                    <video
                        ref={subVideoRef}
                        autoPlay
                        playsInline
                        className="room-pip-video"
                        onLoadedMetadata={(e) => {
                            console.log('[DEBUG] Sub video loadedmetadata:', {
                                videoWidth: e.currentTarget.videoWidth,
                                videoHeight: e.currentTarget.videoHeight,
                                readyState: e.currentTarget.readyState,
                                paused: e.currentTarget.paused,
                                currentSrc: e.currentTarget.currentSrc,
                                srcObject: e.currentTarget.srcObject
                            });
                            
                            const videoTrack = (subVideoRef.current?.srcObject as MediaStream)
                                ?.getTracks()
                                .find(track => track.kind === 'video');

                            const audioTrack = (subVideoRef.current?.srcObject as MediaStream)
                                ?.getTracks()
                                .find(track => track.kind === 'audio');
                                
                            console.log('[DEBUG] Sub video track details:', {
                                enabled: videoTrack?.enabled,
                                muted: videoTrack?.muted,
                                readyState: videoTrack?.readyState,
                                id: videoTrack?.id,
                                label: videoTrack?.label,
                                settings: videoTrack?.getSettings()
                            });

                            console.log('[DEBUG] Sub audio track details:', {
                                enabled: audioTrack?.enabled,
                                muted: audioTrack?.muted,
                                readyState: audioTrack?.readyState,
                                id: audioTrack?.id,
                                label: audioTrack?.label,
                                settings: audioTrack?.getSettings()
                            });

                            // Force play attempt
                            e.currentTarget.play().catch(err => {
                                console.error('[DEBUG] Auto-play failed:', err);
                            });
                        }}
                        onPlay={() => {
                            console.log('[DEBUG] Sub video started playing');
                            // 检查视频轨道状态
                            const videoTrack = (subVideoRef.current?.srcObject as MediaStream)
                                ?.getTracks()
                                .find(track => track.kind === 'video');
                            console.log('[DEBUG] Sub video track status:', {
                                enabled: videoTrack?.enabled,
                                muted: videoTrack?.muted,
                                readyState: videoTrack?.readyState
                            });
                        }}
                        onWaiting={() => console.log('[DEBUG] Sub video waiting')}
                        onStalled={() => console.log('[DEBUG] Sub video stalled')}
                        onSuspend={() => {
                            console.log('[DEBUG] Sub video suspended');
                            
                            // 检查视频流状态
                            if (subVideoRef.current) {
                                const stream = subVideoRef.current.srcObject as MediaStream;
                                const videoTrack = stream?.getVideoTracks()[0];
                                const audioTrack = stream?.getAudioTracks()[0];
                                
                                console.log('[DEBUG] Stream status on suspend:', {
                                    // 视频元素状态
                                    videoElement: {
                                        readyState: subVideoRef.current.readyState,
                                        networkState: subVideoRef.current.networkState,
                                        paused: subVideoRef.current.paused,
                                        currentTime: subVideoRef.current.currentTime,
                                        error: subVideoRef.current.error
                                    },
                                    // 媒体流状态
                                    stream: {
                                        active: stream?.active,
                                        id: stream?.id
                                    },
                                    // 视频轨道状态
                                    videoTrack: videoTrack ? {
                                        enabled: videoTrack.enabled,
                                        muted: videoTrack.muted,
                                        readyState: videoTrack.readyState
                                    } : 'No video track',
                                    // 音频轨道状态
                                    audioTrack: audioTrack ? {
                                        enabled: audioTrack.enabled,
                                        muted: audioTrack.muted,
                                        readyState: audioTrack.readyState
                                    } : 'No audio track'
                                });

                                // 尝试恢复播放
                                if (subVideoRef.current.paused) {
                                    subVideoRef.current.play().catch(err => {
                                        console.error('[DEBUG] Failed to resume video after suspend:', err);
                                    });
                                }
                            }
                        }}
                        onError={(e) => console.error('[DEBUG] Sub video error:', e)}
                    />
                </div>
            </div>

            <div className="room-controls">
                <button 
                    onClick={toggleVideo}
                    className={`room-control-btn ${isVideoEnabled ? 'active' : 'inactive'}`}
                    title={isVideoEnabled ? (t as any)('closeCamera') : (t as any)('openCamera')}
                >
                    {isVideoEnabled ? <FaVideo /> : <FaVideoSlash />}
                </button>
                <button 
                    onClick={toggleAudio}
                    className={`room-control-btn ${isAudioEnabled ? 'active' : 'inactive'}`}
                    title={isAudioEnabled ? (t as any)('closeMicrophone') : (t as any)('openMicrophone')}
                >
                    {isAudioEnabled ? <FaMicrophone /> : <FaMicrophoneSlash />}
                </button>
                <button 
                    onClick={() => setIsChatExpanded(!isChatExpanded)}
                    className={`room-control-btn ${isChatExpanded ? 'active' : ''}`}
                    title={isChatExpanded ? (t as any)('closeChat') : (t as any)('openChat')}
                >
                    {isChatExpanded ? <FaCommentSlash /> : <FaComments />}
                </button>
                <button 
                    onClick={toggleScreenShare}
                    className={`room-control-btn ${isScreenSharing ? 'active' : ''}`}
                    title={isScreenSharing ? (t as any)('stopShare') : (t as any)('shareScreen')}
                >
                    <FaDesktop />
                </button>
                {UserType.TEACHER === userType && (
                    <button 
                        onClick={handleBookManagement}
                        className="room-control-btn"
                        title={(t as any)('bookManagement')}
                    >
                        <FaBook />
                    </button>
                )}
                <button 
                    onClick={handleExit}
                    className="room-control-btn"
                    title={(t as any)('exitRoom')}
                >
                    <FaSignOutAlt />
                </button>
            </div>

            <ChatWindow 
                isExpanded={isChatExpanded}
                onClose={() => setIsChatExpanded(false)}
                t={t}
            />

            {showToast && (
                <Toast 
                    message={toastMessage}
                    onClose={() => setShowToast(false)}
                />
            )}

            <BookManagement 
                isOpen={isBookManagementOpen} 
                onClose={() => setIsBookManagementOpen(false)}
            />

            {/* Replace the existing pen button with this new control group */}
            <div className="floating-controls">
                <button
                    className={`floating-control-btn ${isDrawingMode ? 'active' : ''}`}
                    onClick={() => setIsDrawingMode(!isDrawingMode)}
                    title={isDrawingMode ? (t as any)('closeDraw') : (t as any)('openDraw')}
                >
                    <FaPen />
                </button>
                <button
                    className={`floating-control-btn ${isWideMode ? 'active' : ''}`}
                    onClick={toggleWideMode}
                    title={isWideMode ? (t as any)('narrowScreen') : (t as any)('wideScreen')}
                >
                    {isWideMode ? <FaCompressAlt /> : <FaExpandAlt />}
                </button>
            </div>
            
            {/* Drawing Canvas */}
            <DrawingCanvas 
                isOpen={isDrawingMode}
                onClose={() => setIsDrawingMode(false)}
            />
        </div>
    );
};

export default Room;