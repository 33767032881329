import React from 'react';
import './Footer.css';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-section">
          <h3>关于我们</h3>
          <p>我们致力于提供优质的在线教育服务，连接老师与学生，创造更好的学习体验。</p>
        </div>
        
        <div className="footer-section">
          <h3>联系方式</h3>
          <p>邮箱: lixbtilden@gmail.com</p>
          <p>我们诚挚的接收您的意见和建议，如有任何问题，欢迎通过邮件联系我们。</p>
          {/* <p>电话: (123) 456-7890</p> */}
        </div>
        
        <div className="footer-section">
          <h3>快速链接</h3>
          <ul>
            <li><a href="/about">关于我们</a></li>
            {/* <li><a href="/privacy">隐私政策</a></li> */}
            {/* <li><a href="/terms">服务条款</a></li> */}
          </ul>
        </div>
      </div>
      
      <div className="footer-bottom">
        <p>&copy; {currentYear} HAHAAA. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer; 