import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { WebSocketMessage, WebSocketState, WebSocketStatus } from '../../types/websocket';

export interface ChatMessage {
  type: string;
  roomId: string;
  userId: string;
  firstName: string;
  lastName: string;
  text: string;
}

export interface FileMessage {
  type: string;
  roomId: string;
  userId: string;
  firstName: string;
  lastName: string;
  fileName: string;
  fileUrl: string;
  fileSize: number;
  fileType: string;
}

export interface ConnectionMessage {
  type: string;
  roomId: string;
  userId: string;
  [key: string]: any;
}

const initialState: WebSocketState = {
  status: 'disconnected',
  messages: [],
  error: null,
};

export const websocketSlice = createSlice({
  name: 'websocket',
  initialState,
  reducers: {
    setStatus: (state, action: PayloadAction<WebSocketStatus>) => {
      state.status = action.payload;
    },
    addMessage: (state, action: PayloadAction<WebSocketMessage>) => {
      state.messages.push(action.payload);
    },
    setError: (state, action: PayloadAction<string | null>) => {
      state.error = action.payload;
    },
    clearMessages: (state) => {
      state.messages = [];
    },
    reset: () => initialState,
    connect: (state, action: PayloadAction<{
      type: string;
      userId: string;
      [key: string]: any;
    }>) => {},
    disconnect: (state) => {},
    sendChatMessage: (state, action: PayloadAction<ChatMessage>) => {},
    sendFileMessage: (state, action: PayloadAction<FileMessage>) => {},
    sendConnectionMessage: (state, action: PayloadAction<ConnectionMessage>) => {},
    clearLastMessage: (state) => {
      if (state.messages.length > 0) {
        state.messages.pop();
      }
    },
    popLastMessage: {
      prepare: () => ({
        payload: undefined
      }),
      reducer: (state) => {
        if (state.messages.length > 0) {
          state.messages.pop(); //方式1
          // state.messages = state.messages.slice(0, -1); //方式2
        }
      }
    }
  }
});

export const { 
  setStatus, 
  addMessage, 
  setError, 
  clearMessages,
  reset,
  connect,
  disconnect,
  sendChatMessage,
  sendFileMessage,
  sendConnectionMessage,
  clearLastMessage,
  popLastMessage
} = websocketSlice.actions;

export default websocketSlice.reducer;