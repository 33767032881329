import { configureStore } from '@reduxjs/toolkit';
import webrtcMiddleware from './middleware/webrtcMiddleware';
import websocketMiddleware from './middleware/websocketMiddleware';
import roomReducer from './slices/roomSlice';
import uiReducer from './slices/uiSlice';
import userReducer from './slices/userSlice';
import webrtcReducer from './slices/webrtcSlice';
import websocketReducer from './slices/websocketSlice';

export const store = configureStore({
  reducer: {
    ui: uiReducer,
    user: userReducer,
    room: roomReducer,
    websocket: websocketReducer,
    webrtc: webrtcReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    }).concat(webrtcMiddleware, websocketMiddleware)
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
