import { FC, useEffect, useState } from 'react';
import api from '../utils/ApiClient';
import './TeacherAppointmentConfig.css';
import { useAppSelector } from '../store/hooks';

interface AppointmentTimeSlot {
    id: string;
    userId: string;
    date: string;
    startTime: string;
    endTime: string;
    status: string;
}

interface TimeSlotInfo {
    bookable: boolean;
    appointmentTimeSlots: AppointmentTimeSlot[];
}

interface TimeSlotSelection {
    date: Date;
    hour: number;
}

const TeacherAppointmentConfig = () => {
    const { userId } = useAppSelector(state => state.user);
    const [appointmentTimeSlot, setAppointmentTimeSlot] = useState<AppointmentTimeSlot[]>([]);
    const [selectedSlots, setSelectedSlots] = useState<TimeSlotSelection[]>([]);

    useEffect(() => {
        fetchAppointmentTimeSlots();
    }, []);

    const fetchAppointmentTimeSlots = async () => {
        try {
            const response = await api.get<AppointmentTimeSlot[]>(`/appointments/teacher/${userId}/get`);
            console.info("response.data", response.data);
            setAppointmentTimeSlot(response.data);
        } catch (error) {
            console.error('Error fetching appointments:', error);
        }
    };

    // 将时间字符串转换为小时数
    const getHourFromTimeString = (timeString: string) => {
        return parseInt(timeString.split(':')[0]);
    };

    const formatDate = (date: Date) => {
        return date.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            weekday: 'short'
        });
    };

    // 辅助函数：将日期转换为 YYYY-MM-DD 格式
    const formatDateToString = (date: Date) => {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    // 保持从当前日期开始
    const getNextSevenDays = () => {
        const dates = [];
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        
        for (let i = 0; i < 7; i++) {
            const date = new Date(today);
            date.setDate(today.getDate() + i);
            dates.push(date);
        }
        return dates;
    };

    // 获取时间段的预约信息
    const getTimeSlotInfo = (date: Date, hour: number): TimeSlotInfo => {
        const dateString = formatDateToString(date);
        
        const slotAppointments = appointmentTimeSlot.filter(apt => {
            // 检查日期是否匹配
            if (apt.date !== dateString) return false;
            
            // 获取预约的开始和结束小时及分钟
            const startHour = parseInt(apt.startTime.split(':')[0]);
            const endHour = parseInt(apt.endTime.split(':')[0]);
            
            // 修改判断逻辑：如果当前小时等于开始小时，就认为是已预约
            return hour === startHour;
        });

        return {
            bookable: slotAppointments.length > 0,
            appointmentTimeSlots: slotAppointments
        };
    };

    // 更新 getTimeLineClass 函数
    const getTimeLineClass = (date: Date, hour: number) => {
        const { bookable } = getTimeSlotInfo(date, hour);
        const isSelected = selectedSlots.some(
            slot => slot.date.getTime() === date.getTime() && slot.hour === hour
        );
        return `time-block ${
            bookable ? 'time-block-booked' : 
            isSelected ? 'time-block-selected' : 'time-block-available'
        }`;
    };

    // 添加工具提示内容生成函数
    const getTimeSlotTooltip = (date: Date, hour: number) => {
        const nextHour = hour + 1;
        return `${formatDate(date)}, ${formatTime(hour)}-${formatTime(nextHour)}`;
    };

    const formatTime = (hour: number) => {
        if (hour === 24) return '00:00';
        return `${hour.toString().padStart(2, '0')}:00`;
    };

    // 更新点击处理函数
    const handleTimeBlockClick = (date: Date, hour: number) => {
        const { bookable } = getTimeSlotInfo(date, hour);
        if (bookable) return; // 如果设置可预约，不允许选择

        setSelectedSlots(prev => {
            const isAlreadySelected = prev.some(
                slot => slot.date.getTime() === date.getTime() && slot.hour === hour
            );
            
            if (isAlreadySelected) {
                // 如果已选中，则取消选择
                return prev.filter(
                    slot => !(slot.date.getTime() === date.getTime() && slot.hour === hour)
                );
            } else {
                // 如果未选中，则添加到选中列表
                return [...prev, { date, hour }];
            }
        });
    };

    const handleConfirm = async () => {
        if (selectedSlots.length === 0) return;

        try {
            const timeSlots = selectedSlots.map(slot => {
                const endHour = slot.hour + 1;
                return {
                    date: formatDateToString(slot.date),
                    startTime: `${slot.hour.toString().padStart(2, '0')}:00`,
                    endTime: endHour === 24 ? '00:00' : `${endHour.toString().padStart(2, '0')}:00`,
                };
            });

            console.info("timeSlots:", timeSlots)

            await api.post(`/appointments/teacher/${userId}/available-slots`, timeSlots);
            setSelectedSlots([]); // 清空选择
            await fetchAppointmentTimeSlots();
        } catch (error) {
            console.error('Error saving time slots:', error);
        }
    };

    const handleCancel = () => {
        setSelectedSlots([]); // 清空选择
    };

    return (
        <div className="appointment-container">
            <div className="timeline-container">
                {getNextSevenDays().map((date, dayIndex) => (
                    <div key={dayIndex} className="day-row">
                        <span className="day-label">{formatDate(date)}</span>
                        <div>
                            {[...Array(24)].map((_, hour) => (
                                <div
                                    key={hour}
                                    className={getTimeLineClass(date, hour)}
                                    data-tooltip={getTimeSlotTooltip(date, hour)}
                                    onClick={() => handleTimeBlockClick(date, hour)}
                                >
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
            {selectedSlots.length > 0 && (
                <div className="action-buttons">
                    <button onClick={handleConfirm}>确认</button>
                    <button onClick={handleCancel}>取消</button>
                </div>
            )}
        </div>
    );
};

export default TeacherAppointmentConfig;