import { useAppDispatch } from '../store/hooks';
import { setConfirmDialogAwait } from '../store/slices/uiSlice';

export const useAwaitConfirmDialog = () => {
    const dispatch = useAppDispatch();

    const showConfirmDialogAwait = (title: string, message: string): Promise<boolean> => {
        return new Promise((resolve) => {
            dispatch(setConfirmDialogAwait({
                isOpen: true,
                title,
                message,
                resolvePromise: resolve
            }));
        });
    };

    return { showConfirmDialogAwait };
};